import React, { useEffect, useState } from "react";
import { useTheme } from "../../ThemeProvider";
import "./mysteryDetail.css";

import joinTelegram from "../../assets/svg/criteria.svg";
import openBox from "../../assets/svg/trigger.svg";
import getGifts from "../../assets/svg/reward.svg";

import usdt from "../../assets/svg/usdtT.png";
import token3VO from "../../assets/svg/3voT.png";

import card3vo from "../../assets/images/3voCard.webp";
import chainCard from "../../assets/images/chainGPT.webp";
import emoney from "../../assets/images/emoney.webp";
import redbelly from "../../assets/images/redbelly.webp";
import gains from "../../assets/images/gains.webp";
import soon from "../../assets/images/soon.webp";

import box from "../../assets/images/boxPhoto.webp";
import cardBox from "../../assets/images/cardBox.webp";
import cardCase from "../../assets/images/case.webp";

import chainGptD from "../../assets/partners/chaingpt.svg";
import chainGptL from "../../assets/partners/chaingptL.webp";
import redbellyD from "../../assets/partners/redbellyD.webp";
import redbellyL from "../../assets/partners/redbellyL.webp";
import gainsD from "../../assets/partners/gains.svg";
import gainsL from "../../assets/partners/gains.svg";
import arenaD from "../../assets/partners/arenaGamesD.png";
import arenaL from "../../assets/partners/arenaGamesL.png";
import emoneyD from "../../assets/partners/emoneyD.webp";
import emoneyL from "../../assets/partners/emoneyL.webp";
import cookieD from "../../assets/partners/cookie3DD.png";
import cookieL from "../../assets/partners/cookie3LL.png";
import artheraD from "../../assets/partners/artheraD.png";
import artheraL from "../../assets/partners/artheraL.png";
import helicodeD from "../../assets/partners/helicodeD.png";
import helicodeL from "../../assets/partners/helicodeL.png";
import scholesD from "../../assets/partners/scholes.png";
import scholesL from "../../assets/partners/scholesL.png";
import kimaD from "../../assets/partners/kimaD.png";
import kimaL from "../../assets/partners/kimaL.png";
import coinD from "../../assets/partners/coinpaprikaD.png";
import coinL from "../../assets/partners/coinPaprikaL.png";
import whiteBridgeD from "../../assets/partners/whitebridgeD.png";
import whiteBridgeL from "../../assets/partners/whitebridgeL.png";
import chainawareD from "../../assets/partners/chainawareD.png";
import chainawareL from "../../assets/partners/chainawareL.png";
import forceFiD from "../../assets/partners/forcefiD.png";
import forceFiL from "../../assets/partners/forcefiL.png";
import klinkD from "../../assets/partners/klinkD.png";
import klinkL from "../../assets/partners/klinkL.png";
import skillD from "../../assets/partners/skillfulD.webp";
import skillL from "../../assets/partners/skillfulL.webp";
import veridaD from "../../assets/partners/veridaD.png";
import veridaL from "../../assets/partners/veridaL.png";

function MysteryDetails() {
  const { theme } = useTheme();

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 850) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const backedData = [
    {
      name: "ChainGPT",
      logoD: chainGptD,
      logoL: chainGptL,
      link: "https://www.chaingpt.org/",
    },
    {
      name: "Redbelly Network",
      logoD: redbellyD,
      logoL: redbellyL,
      link: "https://www.redbelly.network/",
    },
    {
      name: "Gains Associates",
      logoD: gainsD,
      logoL: gainsL,
      link: "https://www.gains-associates.com/",
    },
    {
      name: "Arena Games",
      logoD: arenaD,
      logoL: arenaL,
      link: "https://arenavs.com/",
    },
    {
      name: "E-Money Network",
      logoD: emoneyD,
      logoL: emoneyL,
      link: "https://emoney.io/",
    },
    {
      name: "Cookie3",
      logoD: cookieD,
      logoL: cookieL,
      link: "https://www.cookie3.com/",
    },
    {
      name: "Arthera Chain",
      logoD: artheraD,
      logoL: artheraL,
      link: "https://www.arthera.net/",
    },
    {
      name: "Helicode",
      logoD: helicodeD,
      logoL: helicodeL,
      link: "https://helicode.xyz/",
    },
    {
      name: "Scholes",
      logoD: scholesD,
      logoL: scholesL,
      link: "https://www.scholes.xyz/",
    },
    {
      name: "Kima",
      logoD: kimaD,
      logoL: kimaL,
      link: "https://www.kima.network/",
    },
    {
      name: "CoinPaprkia",
      logoD: coinD,
      logoL: coinL,
      link: "https://coinpaprika.com/",
    },
    {
      name: "WhiteBridge",
      logoD: whiteBridgeD,
      logoL: whiteBridgeL,
      link: "https://whitebridge.network/",
    },
    {
      name: "ChainAware.ai",
      logoD: chainawareD,
      logoL: chainawareL,
      link: "https://chainaware.ai/",
    },
    {
      name: "ForceFi",
      logoD: forceFiD,
      logoL: forceFiL,
      link: "https://www.forcefi.io/",
    },
    {
      name: "Klink Finance",
      logoD: klinkD,
      logoL: klinkL,
      link: "https://www.klinkfinance.com/",
    },
    {
      name: "Verida",
      logoD: veridaD,
      logoL: veridaL,
      link: "https://www.verida.network/",
    },
    {
      name: "Skillful AI",
      logoD: skillD,
      logoL: skillL,
      link: "https://skillfulai.io/",
    },
  ];

  return (
    <div className={`mysteryDetails ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="mysteryDetails-container">
        <div className="mysteryDetails-title">
          <div>
            <div className="mysteryDetails-title-tit"> Open Boxes</div>
          </div>
        </div>
        <div className="mysteryBox-how-to">
          <div className="mysteryBox-how-to-item">
            <div className="mysteryBox-how-to-item-image">
              <img src={joinTelegram} alt="Join Telegram"></img>
            </div>
            <div className="mysteryBox-how-to-item-text">
              <div className="mysteryBox-how-to-item-text-title">Criteria</div>
              <div className="mysteryBox-how-to-item-text-desc">
                Try and find the correct criteria to set of a box in any of the
                groups that has 3VO Mystery Box Bot
              </div>
            </div>
          </div>
          <div className="mysteryBox-how-to-item">
            <div className="mysteryBox-how-to-item-image">
              <img src={openBox} alt="Join Telegram"></img>
            </div>
            <div className="mysteryBox-how-to-item-text">
              <div className="mysteryBox-how-to-item-text-title">Triggers</div>
              <div className="mysteryBox-how-to-item-text-desc">
                When the criteria are met, the box gets triggered and stays in
                the group for 30 seconds
              </div>
            </div>
          </div>
          <div className="mysteryBox-how-to-item">
            <div className="mysteryBox-how-to-item-image">
              <img src={getGifts} alt="Join Telegram"></img>
            </div>
            <div className="mysteryBox-how-to-item-text">
              <div className="mysteryBox-how-to-item-text-title">Rewards</div>
              <div className="mysteryBox-how-to-item-text-desc">
                Check what you found in the box by preforming a certain task,
                tokens are cards can drop from a box
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="mysteryDetails-title">
          <div>
            <div className="mysteryDetails-title-tit"> What’s In The Box!</div>
          </div>
        </div>
        <div className="what-in-the-box">
          <div className="what-in-the-box-card">
            <div className="what-in-the-box-card-title">Tokens</div>
            <div className="what-in-the-box-card-picture2">
              <img src={usdt} alt=""></img>
              <img src={token3VO} alt=""></img>
            </div>
          </div>
          <div className="what-in-the-box-card">
            <div className="what-in-the-box-card-title">Cards</div>
            <div className="what-in-the-box-card-picture">
              <img src={card3vo} alt="3VO Card"></img>
            </div>
          </div>
        </div>
        <div className="mysteryDetails-title">
          <div>
            <div className="mysteryDetails-title-tit">
              Gotta Collect Them All!
            </div>
          </div>
        </div>
        <div className="cards-container">
          <div className="cards-cardImage">
            <img src={card3vo} alt="3VO Card"></img>
          </div>
          <div className="cards-cardImage">
            <img src={chainCard} alt="3VO Card"></img>
          </div>
          <div className="cards-cardImage">
            <img src={emoney} alt="3VO Card"></img>
          </div>
          <div className="cards-cardImage">
            <img src={redbelly} alt="3VO Card"></img>
          </div>
          <div className="cards-cardImage">
            <img src={gains} alt="3VO Card"></img>
          </div>
          <div className="cards-cardImage">
            <img src={soon} alt="3VO Card"></img>
          </div>
        </div>
        {!isMobile ? (
          <div className="mysteryboxfeature">
            <div className="socialCards-feature">
              <div className="socialCards-text">
                <div className="socialCards-text-title">
                  Partner Loot Chests
                </div>
                <div className="socialCards-text-description">
                  - Every 10 cards collected from a partner to earn entry to
                  that partner’s Loot Chest! <br />- These special chests are
                  filled with exclusive rewards! <br />- The more entries you
                  collect, the greater your chances to win!
                </div>
              </div>
              <div className="socialCards-image-container-third-border">
                <div className="socialCards-image-container-third">
                  <img src={cardCase} alt="3VO Tokens" />
                </div>
              </div>
            </div>
            <div className="socialCards-feature">
              <div className="socialCards-image-container-fourth-border">
                <div
                  className="socialCards-image-container-fourth"
                  style={{ alignItems: "flex-start" }}
                >
                  <img
                    src={box}
                    className="align-left-image"
                    alt="3VO Tokens"
                  />
                </div>
              </div>
              <div className="socialCards-text-r">
                <div className="socialCards-text-title">
                  The 3VO Hub Grand Chest
                </div>
                <div className="socialCards-text-description">
                  - The ultimate prize chest of the entire campaign, packed with
                  extraordinary rewards!
                  <br /> - To earn a ticket for the Grand Chest, collect at
                  least 10 cards from every participating project throughout the
                  campaign.
                </div>
              </div>
            </div>
            <div className="socialCards-feature">
              <div className="socialCards-text">
                <div className="socialCards-text-title">Race to 300 Cards</div>
                <div className="socialCards-text-description">
                  - Each project hosts a competitive race where participants
                  strive to collect 300 cards as quickly as possible! <br />-
                  The first participant to reach 300 cards from a project will
                  score the most rewards.
                </div>
              </div>
              <div className="socialCards-image-container-third-border">
                <div className="socialCards-image-container-third">
                  <img src={cardBox} alt="3VO Tokens" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mysteryboxfeature">
            {" "}
            <div className="socialCards-feature">
              <div className="socialCards-image-container-fourth-border">
                <div
                  className="socialCards-image-container-fourth"
                  style={{ alignItems: "flex-end" }}
                >
                  <img src={cardCase} alt="3VO Tokens" />
                </div>
              </div>
              <div className="socialCards-text">
                <div
                  className="socialCards-text-title"
                  style={{ fontSize: 18 }}
                >
                  Partner Loot Chests
                </div>
                <div
                  className="socialCards-text-description"
                  style={{ fontSize: 14 }}
                >
                  - every 10 cards collected from a partner to earn entry to
                  that partner’s Loot Chest! <br />- These special chests are
                  filled with exclusive rewards! <br />- The more entries you
                  collect, the greater your chances to win!
                </div>
              </div>
            </div>
            <div className="socialCards-feature">
              <div className="socialCards-image-container-fourth-border">
                <div
                  className="socialCards-image-container-fourth"
                  style={{ alignItems: "flex-start" }}
                >
                  <img src={box} alt="3VO Tokens" />
                </div>
              </div>
              <div className="socialCards-text">
                <div
                  className="socialCards-text-title"
                  style={{ fontSize: 18 }}
                >
                  {" "}
                  The 3VO Hub Grand Chest
                </div>
                <div
                  className="socialCards-text-description"
                  style={{ fontSize: 14 }}
                >
                  - The ultimate prize chest of the entire campaign, packed with
                  extraordinary rewards!
                  <br /> - To earn a ticket for the Grand Chest, collect at
                  least 10 cards from every participating project throughout the
                  campaign.
                </div>
              </div>
            </div>
            <div className="socialCards-feature">
              <div className="socialCards-image-container-fourth-border">
                <div
                  className="socialCards-image-container-fourth"
                  style={{ alignItems: "flex-end" }}
                >
                  <img src={cardBox} alt="3VO Tokens" />
                </div>
              </div>
              <div className="socialCards-text">
                <div
                  className="socialCards-text-title"
                  style={{ fontSize: 18 }}
                >
                  Race to 300 Cards
                </div>
                <div
                  className="socialCards-text-description"
                  style={{ fontSize: 14 }}
                >
                  - Each project hosts a competitive race where participants
                  strive to collect 300 cards as quickly as possible! <br />-
                  The first participant to reach 300 cards from a project will
                  score the most rewards.
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mysteryDetails-title">
          <div>
            <div className="mysteryDetails-title-tit2">Meet Our Partners</div>
            <div className="mysteryBox-title-subtitle">
              The 3VO Mystery Box campaign boasts over 20 amazing partners, and
              we'll be unveiling new ones every day!
            </div>
          </div>
        </div>
        <div className="backed-icons">
          {backedData.map((item, index) => (
            <div className="backed-icon" key={index}>
              <a href={item.link} target="_blank" rel="noreferrer">
                <img
                  src={theme === "light" ? item.logoL : item.logoD}
                  alt={`Backed by ${item.name}`}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MysteryDetails;
