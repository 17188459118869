import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./singleUsecaseHero.css";

function SingleUsecaseHero({ title, subtitle }) {
  const { theme } = useTheme();

  return (
    <div
      className={`singleUsecaseHero ${theme === "light" ? "" : "dark-theme"}`}
    >
      <div className="singleUsecaseHero-container">
        <div className="singleUsecaseHero-title">
          <div>
            <div className="singleUsecaseHero-title-title">{title}</div>
          </div>
          <div className="singleUsecaseHero-title-subtitle">{subtitle}</div>
        </div>
      </div>
    </div>
  );
}

export default SingleUsecaseHero;
