import React, { useEffect } from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import TokenHero from "../token/tokenHero/tokenHero.js";
import TokenStatement from "../token/tokenStatement/tokenStatement.js";
import TokenUtility from "../token/tokenUtility/tokenUtility.js";
import Tokenomics from "../token/tokenomics/tokenomics.js";
import TokenPresale from "../token/tokenPresale/tokenPresale.js";
import TokenCall from "../token/tokenCall/tokenCall.js";

function Token() {
  const { theme } = useTheme();

  document.title = "3VO | Token";

  useEffect(() => {
    // Function to handle auto-scrolling
    const handleAutoScroll = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          let offset = element.getBoundingClientRect().top;
          if (hash === "#tokenomics") {
            offset += 140; // Add additional offset for #advisors
          }
          window.scrollTo({
            top: window.pageYOffset + offset - 70, // Adjusted for header
            behavior: "smooth",
          });
        }
      }
    };

    handleAutoScroll();
    window.addEventListener("hashchange", handleAutoScroll);

    return () => {
      window.removeEventListener("hashchange", handleAutoScroll);
    };
  }, []);

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <TokenHero />
      <TokenStatement />
      <TokenUtility />
      <Tokenomics />
      {/* <TokenPresale /> */}
      <TokenCall />
      <Footer />
    </div>
  );
}

export default Token;
