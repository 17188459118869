import React, { useState, useEffect } from "react";

function CountdownTimer({ targetDate }) {
  const calculateTimeLeft = () => {
    // Parse the target date as UTC
    const target = new Date(targetDate);
    const now = new Date();

    // Get the current time in UTC
    const nowUTC = new Date(now.toUTCString());

    // Calculate the difference in milliseconds
    const difference = target.getTime() - nowUTC.getTime();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const formatTimeLeft = (timeLeft) => {
    const { days, hours, minutes, seconds } = timeLeft;

    return `${String(days).padStart(2, "0")}:${String(hours).padStart(
      2,
      "0"
    )}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <div className="countdown">
      {Object.keys(timeLeft).length ? (
        <span>Season 1 ends in {formatTimeLeft(timeLeft)}</span>
      ) : (
        <span>Season Has Ended!</span>
      )}
    </div>
  );
}

export default CountdownTimer;
