import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./dappsHero.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function DappsHero() {
  const { theme } = useTheme();

  return (
    <div className={`dappsHero ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="dappsHero-container">
        <div className="dappsHero-title">
          <div>
            <div className="dappsHero-title-title">
              <span className="specific-text">3VO</span> Dapps
            </div>
          </div>
          <div className="dappsHero-title-subtitle">
            Customizable Dapps instead of static social media pages
          </div>
        </div>
      </div>
      <div className="dappsHero-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default DappsHero;
