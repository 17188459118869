import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./freedomFeatures.css";

import plus from "../../assets/svg/plus.svg";
import logo from "../../assets/svg/logo-inner-colored.svg";

function FreedomFeatures() {
  const { theme } = useTheme();

  const features = [
    {
      title: "Dapp Builder",
      description: "No-code canvas to build user Dapps",
      icon: plus,
    },
    {
      title: "Mint Token",
      description:
        "Create utility tokens with ready-made customizable smart contracts",
      icon: plus,
    },
    {
      title: "DAO",
      description:
        "Turn any community or organization into a DAO.",
      icon: plus,
    },
    {
      title: "Stream Studio",
      description:
        "Full control over your streams and interaction with your fans",
      icon: plus,
    },
    {
      title: "Analytics",
      description: "Analytics on User Created Dapps",
      icon: plus,
    },
    {
      title: "AI Assistant",
      description: "Built-in AI assistant to build and deploy user Dapps",
      icon: plus,
    },
  ];

  return (
    <div className={`freedomFeatures ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="freedomFeatures-container">
        <div className="freedomFeatures-title">Features</div>
        <div className="freedomFeatures-features">
          {features.map((feature, index) => (
            <div key={index} className="freedomFeatures-feature">
              <div className="freedomFeatures-feature-icon">
                <img src={feature.icon} alt="Feature Icon" />
              </div>
              <div className="freedomFeatures-feature-text">
                <div className="freedomFeatures-feature-title">
                  {feature.title}
                </div>
                <div className="freedomFeatures-feature-description">
                  {feature.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="freedomFeatures-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default FreedomFeatures;
