import React, { useState, useEffect } from "react";
import { useTheme } from "../../ThemeProvider";
import "./projectRoadmap.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

import arrow from "../../assets/svg/Vector.svg";

function ProjectRoadmap() {
  const { theme } = useTheme();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 450) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      id="roadmap"
      className={`projectRoadmap ${theme === "light" ? "" : "dark-theme"}`}
    >
      <div className="projectRoadmap-container">
        <div className="projectRoadmap-title">
          <div>
            <div className="projectRoadmap-title-title">Roadmap</div>
          </div>
        </div>
      </div>
      {!isMobile ? (
        <div className="projectRoadmap-container">
          <div className="projectRoadmap-roadmap">
            <div className="projectRoadmap-roadmap-section1">
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>Legal Registration</div>
                  <div>Team Formation</div>
                  <div>Documentation</div>
                  <div>UI/UX Design</div>
                  <div>Technical Infrastructure</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2023
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q3
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>3VO DeFi Wallet</div>
                  <div>3VO App ( Alpha Build )</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2024
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q1
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>3VO App ( Public )</div>
                  <div>3VO Web ( Alpha )</div>
                  <div>3VO Token Exchange Listing</div>
                  <div>Brand Onboarding</div>
                  <div>Test Market Deployment</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2024
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q3
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>
                    3VO Dapps ( no-code DApp Builder , Tokenization Tool )
                  </div>
                  <div>Fiat on/off ramps</div>
                  <div>Virtual Card</div>
                  <div>Crypto ePoS</div>
                  <div>Token Exchange System</div>
                  <div>App Deployment in 6 Markets</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2025
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q1
                  </div>
                </div>
              </div>
            </div>
            <div className="projectRoadmap-roadmap-line">
              <div className="projectRoadmap-roadmap-line-circle"></div>
              <div className="projectRoadmap-roadmap-line-arrow">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
            <div className="projectRoadmap-roadmap-section2">
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>Angel Round</div>
                  <div>3VO Token Smart Contract</div>
                  <div>Backend & Internal Builds</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2023
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q4
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>3VO App ( Public Beta )</div>
                  <div>Seed/Strategic Round</div>
                  <div>Early Users Onboarding</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2024
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q2
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>Dapp Builder</div>
                  <div>3VO Web ( Beta )</div>
                  <div>Full 3VO Token Utilization</div>
                  <div>User Acquisition Campaign </div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2024
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q4
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>SDK</div>
                  <div>Streaming Protocol</div>
                  <div>3VO DAO</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2025
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q2
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="projectRoadmap-container">
          <div className="projectRoadmap-roadmap">
            <div className="projectRoadmap-roadmap-section1">
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>Legal Registration</div>
                  <div>Team Formation</div>
                  <div>Documentation</div>
                  <div>UI/UX Design</div>
                  <div>Technical Infrastructure</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2023
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q3
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>Angel Round</div>
                  <div>3VO Token Smart Contract</div>
                  <div>Backend & Internal Builds</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2023
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q4
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>3VO DeFi Wallet</div>
                  <div>3VO App ( Alpha Build )</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2024
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q1
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>3VO App ( Public Beta )</div>
                  <div>Seed/Strategic Round</div>
                  <div>Early Users Onboarding</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2024
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q2
                  </div>
                </div>
              </div>

              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>3VO App ( Public )</div>
                  <div>3VO Web ( Alpha )</div>
                  <div>3VO Token Exchange Listing</div>
                  <div>Brand Onboarding</div>
                  <div>Test Market Deployment</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2024
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q3
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>Dapp Builder</div>
                  <div>3VO Web ( Beta )</div>
                  <div>Full 3VO Token Utilization</div>
                  <div>User Acquisition Campaign </div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2024
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q4
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>
                    3VO Dapps ( no-code DApp Builder , Tokenization Tool )
                  </div>
                  <div>Fiat on/off ramps</div>
                  <div>Virtual Card</div>
                  <div>Crypto ePoS</div>
                  <div>Token Exchange System</div>
                  <div>App Deployment in 6 Markets</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2025
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q1
                  </div>
                </div>
              </div>
              <div className="projectRoadmap-roadmap-filler"></div>
              <div className="projectRoadmap-roadmap-item">
                <div className="projectRoadmap-roadmap-item-text">
                  <div>SDK</div>
                  <div>Streaming Protocol</div>
                  <div>3VO DAO</div>
                </div>
                <div className="projectRoadmap-roadmap-item-circle">
                  <div className="projectRoadmap-roadmap-item-circle-line"></div>
                  <div className="projectRoadmap-roadmap-item-circle-year">
                    2025
                  </div>
                  <div className="projectRoadmap-roadmap-item-circle-quarter">
                    Q2
                  </div>
                </div>
              </div>
            </div>
            <div className="projectRoadmap-roadmap-line">
              <div className="projectRoadmap-roadmap-line-circle"></div>
              <div className="projectRoadmap-roadmap-line-arrow">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="projectRoadmap-background">
        <img src={logo} alt="3vo-superapp" />
      </div>
    </div>
  );
}

export default ProjectRoadmap;
