import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./tokenUtility.css";

import tag from "../../assets/svg/tag.svg";
import star from "../../assets/svg/star.svg";
import bridge from "../../assets/svg/bridge.svg";
import incentive from "../../assets/svg/incentives.svg";
import protocol from "../../assets/svg/protocol.svg";
import liquid from "../../assets/svg/liquid.svg";
import logo from "../../assets/svg/logo-inner-colored.svg";

function TokenUtility() {
  const { theme } = useTheme();

  const features = [
    {
      title: "Transaction Fees",
      description: "EVM Compatible Description",
      icon: tag,
    },
    {
      title: "Premium Tools",
      description: "Secure Description",
      icon: star,
    },
    {
      title: "Bridge Token",
      description: "NFT Username Description",
      icon: bridge,
    },
    {
      title: "User Incentives",
      description: "Built-in Wallet Description",
      icon: incentive,
    },
    {
      title: "Protocol Management",
      description: "End-to-End Encryption Message Description",
      icon: protocol,
    },
    {
      title: "Liquidity for the Social Tokens",
      description: "Fiat Off-Ramp / On-Ramp Description",
      icon: liquid,
    },
  ];

  return (
    <div className={`tokenUtility ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="tokenUtility-container">
        <div className="tokenUtility-title">Utilities</div>
        <div className="tokenUtility-features">
          {features.map((feature, index) => (
            <div key={index} className="tokenUtility-feature">
              <div className="tokenUtility-feature-icon">
                <img src={feature.icon} alt="Feature Icon" />
              </div>
              <div className="tokenUtility-feature-text">
                <div className="tokenUtility-feature-title">
                  {feature.title}
                </div>
                <div className="tokenUtility-feature-description">
                  {feature.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="tokenUtility-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default TokenUtility;
