import React from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import SingleUsecaseCall from "../singleUsecase/singleUsecaseCall/singleUsecaseCall.js";
import SingleUsecaseHero from "../singleUsecase/singleUsecaseHero/singleUsecaseHero.js";
import SingleUsecaseDescription from "../singleUsecase/singleUsecaseDescription/singleUsecaseDescription.js";
import SingleUsecasePhoto from "../singleUsecase/singleUsecasePhoto/singleUsecasePhoto.js";

import a from "../assets/tasty/01.webp";
import b from "../assets/tasty/02.webp";
import c from "../assets/tasty/03.webp";
import d from "../assets/tasty/04.webp";
import e from "../assets/tasty/05.webp";
import f from "../assets/tasty/06.webp";

import tasty from "../assets/images/tastyPizzaPage.webp";

function TastyPizza() {
  const { theme } = useTheme();

  document.title = "3VO | Tasty Pizza";

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />

      <SingleUsecaseHero title="Tasty Pizza" subtitle="" />
      <SingleUsecasePhoto img={a} name="Tasty Pizza" />
      <SingleUsecasePhoto img={b} name="Tasty Pizza" />
      <SingleUsecasePhoto img={c} name="Tasty Pizza" />
      <SingleUsecasePhoto img={d} name="Tasty Pizza" />
      <SingleUsecasePhoto img={e} name="Tasty Pizza" />
      <SingleUsecasePhoto img={f} name="Tasty Pizza" />
      <SingleUsecaseCall />

      <Footer />
    </div>
  );
}

export default TastyPizza;
