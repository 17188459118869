import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mysteryBoxMain.css";

function MysteryBoxMain() {
  const { theme } = useTheme();

  return (
    <div className={`mysteryBoxMain ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="mysteryBoxMain-container">
        <div className="mysteryBoxMain-title">
          <div>
            <div className="mysteryBoxMain-title-tit">
               Mark Your Calendars!
            </div>
            <div className="mysteryBox-title-title">
              <span className="specific-text">October 1st, 2024</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MysteryBoxMain;
