import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./socialHero.css";
import image from "../../assets/images/socialmobile.webp";

function SocialHero() {
  const { theme } = useTheme();

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className={`socialHero ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="socialHero-container">
        <div className="socialHero-title">
          <div>
            <div className="socialHero-title-title">
              <span className="specific-text">3VO</span> App
            </div>
          </div>
          <div className="socialHero-title-subtitle">
            One app for social & crypto. An intuitive multichain wallet for all
            digital value​
          </div>
          <div className="socialHero-button-container">
            <div className="socialHero-button" onClick={scrollToBottom}>
              <div className="socialHero-button-title">Get 3VO App</div>
            </div>
          </div>
        </div>
        <div className="socialHero-background">
          <img src={image} alt="3vo-superapp"></img>
        </div>
      </div>
    </div>
  );
}

export default SocialHero;
