import React from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import SingleUsecaseCall from "../singleUsecase/singleUsecaseCall/singleUsecaseCall.js";
import SingleUsecaseHero from "../singleUsecase/singleUsecaseHero/singleUsecaseHero.js";
import SingleUsecaseDescription from "../singleUsecase/singleUsecaseDescription/singleUsecaseDescription.js";
import SingleUsecasePhoto from "../singleUsecase/singleUsecasePhoto/singleUsecasePhoto.js";

import a from "../assets/tucker/01.webp";
import b from "../assets/tucker/02.webp";
import c from "../assets/tucker/03.webp";
import d from "../assets/tucker/04.webp";
import e from "../assets/tucker/05.webp";

function TuckerReports() {
  const { theme } = useTheme();

  document.title = "3VO | Tucker Reports";

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <SingleUsecaseHero title="Tucker Reports" subtitle="" />
      <SingleUsecasePhoto img={a} name="tucker reports" />
      <SingleUsecasePhoto img={b} name="tucker reports" />
      <SingleUsecasePhoto img={c} name="tucker reports" />
      <SingleUsecasePhoto img={d} name="tucker reports" />
      <SingleUsecasePhoto img={e} name="tucker reports" />
      <SingleUsecaseCall />
      <Footer />
    </div>
  );
}

export default TuckerReports;
