import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import logo from "../assets/svg/logo-inner-colored.svg";
import logo2 from "../assets/svg/logo.svg";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";

import voucher from "../assets/images/Voucher.png";
import usdt50 from "../assets/images/50USDT.png";
import usdt45 from "../assets/images/45USDT.png";
import usdt40 from "../assets/images/40USDT.png";
import usdt35 from "../assets/images/35USDT.png";
import usdt30 from "../assets/images/30USDT.png";
import usdt25 from "../assets/images/25USDT.png";
import usdt20 from "../assets/images/20USDT.png";
import usdt15 from "../assets/images/15USDT.png";
import usdt10 from "../assets/images/10USDT.png";
import usdt5 from "../assets/images/5USDT.png";
import lost from "../assets/images/lost.png";

import galxe from "../assets/images/galxe.webp";
import zealy from "../assets/images/zealy.webp";
import gleam from "../assets/images/gleam.webp";

const reCaptchaKey = process.env.REACT_APP_GOOGLE_KEY;

const comp = [
  {
    name: "3vo First Galxe Campaign!",
    image: galxe,
    status: false,
    link: "https://app.galxe.com/quest/ASNN7MLzRXyPBLoYR9aiZ3/GC5mGtTifj",
  },
  {
    name: "Valentines Day Giveaway",
    image: zealy,
    status: false,
    link: "",
  },
  {
    name: "Special Airdrop - Gleam Event",
    image: gleam,
    status: false,
    link: "",
  },
];

function Earn() {
  const { theme } = useTheme();
  const [walletAddress, setWalletAddress] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const canvasRef = useRef(null);
  const [imageDataUrl, setImageDataUrl] = useState("");
  const [foundWinner, setFoundWinner] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(null);
  const [reCaptchaKeyChanged, setReCaptchaKeyChanged] = useState(false);

  const onCaptchaChange = (value) => {
    setIsCaptchaVerified(value ? true : false);
  };

  const resultClass = searchResult.includes("Congratulations")
    ? "winner-message"
    : "non-winner-message";

  useEffect(() => {
    if (foundWinner) {
      drawImageWithWalletAddress();
    }
  }, [foundWinner, searchResult]);

  useEffect(() => {
    setReCaptchaKeyChanged((prev) => !prev);
  }, [theme]);

  const drawImageWithWalletAddress = (imageSrc) => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.src = imageSrc;

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);
        ctx.font = "50px Arial";
        ctx.fillStyle = "#1be8ff";
        ctx.fillText(walletAddress, 150, 150);
        setImageDataUrl(canvas.toDataURL());
      };
    }
  };

  const checkWalletAddress = async () => {
    const uppercaseWalletAddress = walletAddress.toUpperCase();

    try {
      const docRef = doc(firestore, "zealy", uppercaseWalletAddress);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const prizeName = data.Winner.toUpperCase();
        const prizeAmount = data.Amount;
        setSearchResult(
          `🎉 Congratulations! You Have Won ${prizeAmount} ${prizeName}! 🎉`
        );
        setFoundWinner(true);

        let imageSrc;
        if (prizeName === "$3VO") {
          imageSrc = voucher;
        } else {
          switch (prizeAmount) {
            case 50:
              imageSrc = usdt50;
              break;
            case 45:
              imageSrc = usdt45;
              break;
            case 40:
              imageSrc = usdt40;
              break;
            case 35:
              imageSrc = usdt35;
              break;
            case 30:
              imageSrc = usdt30;
              break;
            case 25:
              imageSrc = usdt25;
              break;
            case 20:
              imageSrc = usdt20;
              break;
            case 15:
              imageSrc = usdt15;
              break;
            case 10:
              imageSrc = usdt10;
              break;
            case 5:
              imageSrc = usdt5;
              break;
            default:
              imageSrc = lost;
          }
        }

        drawImageWithWalletAddress(imageSrc);

        await updateDoc(docRef, {
          Checked: true,
        });
      } else {
        setSearchResult("❌ Better Luck Next Time ❌");
        let imageSrc;
        imageSrc = lost;
        drawImageWithWalletAddress(imageSrc);
      }
    } catch (error) {
      console.error("Error searching wallet address: ", error);
      setSearchResult("Error occurred while searching.");
    }
  };

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <div className="embed">
        <div className="embed-container">
          <div className="embed-title">Earn</div>
          <div className="comp-current">
            {comp.map((item, index) =>
              item.status === true ? (
                <a
                  href={item.link}
                  target="_blank"
                  className={`comp-quest-${item.status ? "active" : "done"}`}
                  key={index}
                >
                  <img src={item.image} alt={`Backed by ${item.name}`} />
                  <div className="comp-quest-title">{item.name}</div>
                </a>
              ) : (
                <div
                  href={item.link}
                  target="_blank"
                  className={`comp-quest-${item.status ? "active" : "done"}`}
                  key={index}
                >
                  <img src={item.image} alt={`Backed by ${item.name}`} />
                  <div className="comp-quest-title">{item.name}</div>
                </div>
              )
            )}
          </div>
          <div className="comp-result">
            <div className="comp-result-search">
              <>
                {/* <p>Enter your wallet address</p> */}
                {/* <div className="comp-result-searchbar">
                  <input
                    type="text"
                    value={walletAddress}
                    onChange={(e) => setWalletAddress(e.target.value)}
                    placeholder="Wallet Address"
                  />
                  <button
                    onClick={checkWalletAddress}
                    disabled={!isCaptchaVerified}
                  >
                    {isCaptchaVerified ? "Search" : "reCAPTCHA"}
                  </button>
                </div> */}
                {/* <div
                  className={`comp-result-search-captcha-${
                    isCaptchaVerified ? "done" : "notdone"
                  }`}
                  key={reCaptchaKeyChanged ? "1" : "0"}
                >
                  <ReCAPTCHA
                    sitekey={reCaptchaKey}
                    onChange={onCaptchaChange}
                    theme={theme === "light" ? "light" : "dark"}
                  />
                </div> */}
              </>
            </div>
            {searchResult && <p className={resultClass}>{searchResult}</p>}
            <canvas ref={canvasRef} style={{ display: "none" }} />{" "}
            {imageDataUrl && (
              <>
                <img
                  className="voucher"
                  src={imageDataUrl}
                  alt="Wallet Voucher"
                />
                <div className="voucher-rules">
                  <p>
                    <strong>Disclaimer</strong>
                  </p>
                  <p>
                    <strong>Purpose of Voucher:</strong> This voucher serves
                    solely as a proof of ownership for Tokens.
                  </p>
                  <p>
                    <strong>Token Distribution:</strong> The corresponding
                    Tokens will be credited directly to the winner's designated
                    wallet.
                  </p>
                  <p>
                    <strong>Claiming Tokens:</strong> To claim your Tokens,
                    please verify your winner status by visiting 3vo.me/earn
                    before the 15th of April. Failure to do so will result in
                    disqualification. Upon successful verification, tokens will
                    be dispersed to the winner's wallet within two months
                    following the Token Generation Event (TGE).
                  </p>
                  <p>
                    <strong>Non-Transferable:</strong> This voucher is
                    non-transferable. It is linked exclusively to the
                    recipient's wallet and cannot be transferred to others.
                  </p>
                  <p>
                    <strong>Non-Refundable:</strong> This voucher is
                    non-refundable and holds no monetary value beyond its
                    representation of the Tokens ownership.
                  </p>
                  <p>
                    Details on this Voucher does not have any meaning or value
                    if it doesn't match with the 3VO database.
                  </p>
                </div>
              </>
            )}
          </div>
        </div>{" "}
        <div className="embed-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Earn;
