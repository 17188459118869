import React, { useState, useEffect } from "react";
import { useTheme } from "../../ThemeProvider";
import "./tokenomics.css";

import pieLight from "../../assets/images/pie-Light.webp";
import pieDark from "../../assets/images/pie-Dark.webp";
import pieMLight from "../../assets/images/pieM-Light.webp";
import pieMDark from "../../assets/images/pieM-Dark.webp";
import pieSLight from "../../assets/images/pieS-Light.webp";
import pieSDark from "../../assets/images/pieS-Dark.webp";

import tokenomicsFile from "../../assets/files/3vo-tokenomics-V3.0.pdf";

function Tokenomics() {
  const { theme } = useTheme();
  const [pieImage, setPieImage] = useState(pieLight);
  const [pieMImage, setPieMImage] = useState(pieMLight);
  const [pieSImage, setPieSImage] = useState(pieSLight);

  useEffect(() => {
    const updatePieImage = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1000) {
        setPieImage(theme === "light" ? pieLight : pieDark);
        setPieMImage(theme === "light" ? pieMLight : pieMDark);
        setPieSImage(theme === "light" ? pieSLight : pieSDark);
      } else if (screenWidth >= 850) {
        setPieImage(theme === "light" ? pieMLight : pieMDark);
        setPieMImage(theme === "light" ? pieMLight : pieMDark);
        setPieSImage(theme === "light" ? pieSLight : pieSDark);
      } else if (screenWidth >= 769) {
        setPieImage(theme === "light" ? pieLight : pieDark);
        setPieMImage(theme === "light" ? pieMLight : pieMDark);
        setPieSImage(theme === "light" ? pieSLight : pieSDark);
      } else {
        setPieImage(theme === "light" ? pieSLight : pieSDark);
        setPieMImage(theme === "light" ? pieSLight : pieSDark);
        setPieSImage(theme === "light" ? pieSLight : pieSDark);
      }
    };

    updatePieImage();

    window.addEventListener("resize", updatePieImage);

    return () => {
      window.removeEventListener("resize", updatePieImage);
    };
  }, [theme]);

  const token = [
    { name: "Token Name", value: "3VO" },
    { name: "Token Ticker", value: "$3VO" },
    { name: "Listing Price", value: "$0.01" },
  ];

  const metrics = [
    { name: "Blockchain", value: "ETH, Polygon, BNB" },
    { name: "Project Valuation", value: "$20,000,000" },
    { name: "Initial Marketcap", value: "$651,000" },
    { name: "Token Supply", value: "2,000,000,000" },
    { name: "Token For Sale (%)", value: "27.5%" },
    { name: "Token For Sale", value: "550,000,000" },
  ];

  const vestingRound = [
    {
      name: "Angel Round",
      value:
        "4% unlocked at TGE, 4 month cliff, then linear unlock over 12 months",
    },
    {
      name: "Seed Round",
      value:
        "4% unlocked at TGE, 3 month cliff, then linear unlock over 12 months",
    },
    {
      name: "Private Round",
      value:
        "4% unlocked at TGE, 2 month cliff, then linear unlock over 12 months",
    },
    {
      name: "Strategic Round",
      value:
        "4% unlocked at TGE, 1 month cliff, then linear unlock over 12 months",
    },
    {
      name: "KOL Round",
      value: "20% unlocked at TGE, linear unlock over 4 months",
    },
    {
      name: "IDO Launchpad",
      value: "20% unlocked at TGE, linear unlock over 4 months",
    },
    {
      name: "CEX Launchpad",
      value: "100% unlocked",
    },
  ];

  const vesting = [
    {
      name: "Community and Users Incentives",
      value: "0% unlocked at TGE, linear unlock over 60 months",
    },
    {
      name: "Team",
      value:
        "0% unlocked at TGE, locked for 6 months, then linear unlock over 50 months",
    },
    {
      name: "Advisors and Partnerships",
      value:
        "0% unlocked at TGE, locked for 6 months, then linear unlock over 50 months",
    },
    {
      name: "Ecosystem Development",
      value:
        "0% unlocked at TGE, locked for 12 months, then linear unlock over 48 months",
    },
    {
      name: "Marketing",
      value:
        "0% unlocked at TGE, locked for 12 months, then linear unlock over 48 months",
    },
    {
      name: "Liquidity and Market Making",
      value: "15% unlocked at TGE, linear unlock over 10 months",
    },
    {
      name: "Reserve",
      value:
        "0% unlocked at TGE, locked for 24 months, then linear unlock over 30 months",
    },
  ];

  return (
    <div
      id="tokenomics"
      className={`tokenomics ${theme === "light" ? "" : "dark-theme"}`}
    >
      <div className="tokenomics-container">
        <div className="tokenomics-title">Tokenomics</div>
        <div className="tokenomics-main-container">
          <div className="tokenomics-main-token">
            <div className="tokenomics-main-token-chart">
              <div className="tokenomics-main-token-chart-pie">
                <img src={pieImage} alt="3VO Distrbution " />
              </div>
            </div>
            <div className="tokenomics-main-token-metrics">
              <div className="tokenomics-main-token-metrics-token">
                <div className="tokenomics-main-token-metrics-token-title">
                  Token
                </div>
                <div className="tokenomics-main-token-metrics-token-list">
                  {token.map((metric, index) => (
                    <div
                      className="tokenomics-main-token-metrics-token-list-item"
                      key={index}
                    >
                      <div className="tokenomics-main-token-metrics-token-list-item-name">
                        {metric.name}
                      </div>
                      <div className="tokenomics-main-token-metrics-token-list-item-value">
                        {metric.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="tokenomics-main-token-metrics-metric">
                <div className="tokenomics-main-token-metrics-metric-title">
                  Metrics
                </div>
                <div className="tokenomics-main-token-metrics-metric-list">
                  {metrics.map((metric, index) => (
                    <div
                      className="tokenomics-main-token-metrics-token-list-item"
                      key={index}
                    >
                      <div className="tokenomics-main-token-metrics-token-list-item-name">
                        {metric.name}
                      </div>
                      <div className="tokenomics-main-token-metrics-token-list-item-value">
                        {metric.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="tokenomics-main-vesting">
            <div className="tokenomics-main-vesting-title">
              Vesting Schedule
            </div>
            <div className="tokenomics-main-vesting-container">
              <div className="tokenomics-main-vesting-col">
                {vestingRound.map((vesting, index) => (
                  <div className="tokenomics-main-vesting-item" key={index}>
                    <div className="tokenomics-main-vesting-item-name">
                      {vesting.name}
                    </div>
                    <div className="tokenomics-main-vesting-item-value">
                      {vesting.value}
                    </div>
                  </div>
                ))}
              </div>
              <div className="tokenomics-main-vesting-col">
                {vesting.map((vesting, index) => (
                  <div className="tokenomics-main-vesting-item" key={index}>
                    <div className="tokenomics-main-vesting-item-name">
                      {vesting.name}
                    </div>
                    <div className="tokenomics-main-vesting-item-value">
                      {vesting.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="tokenomics-action">
          <a href={tokenomicsFile} target="_blank" rel="noreferrer">
            <div className="tokenomics-action-button">Detailed Tokenomics</div>
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default Tokenomics;
