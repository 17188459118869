import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./tokenHero.css";
import image from "../../assets/images/coins.webp";

function TokenHero() {
  const { theme } = useTheme();

  return (
    <div className={`tokenHero ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="tokenHero-container">
        <div className="tokenHero-title">
          <div>
            <div className="tokenHero-title-title">
              <span className="specific-text">$3VO</span> Token
            </div>
          </div>
          <div className="tokenHero-title-subtitle">
            The Token for all 3VO Ecosystem
          </div>
          <div className="tokenHero-action">
            <a href="/presale" target="_blank" rel="noreferrer">
              <div className="tokenHero-action-button">Join Pre-sale</div>
            </a>
          </div>
        </div>
        <div className="tokenHero-background">
          <img src={image} alt="3vo-superapp"></img>
        </div>
      </div>
    </div>
  );
}

export default TokenHero;
