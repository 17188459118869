import React from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import DefiHero from "../defi/defiHero/defiHero.js";
import DefiPhotos from "../defi/defiPhotos/defiPhotos.js";
import DefiFeatures from "../defi/defiFeatures/defiFeatures.js";
import DefiDescription from "../defi/defiDescription/defiDescription.js";
import DefiDownload from "../defi/defiDownload/defiDownload.js";

function Defi() {
  const { theme } = useTheme();

  document.title = "3VO | Wallet";

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <DefiHero />
      <DefiPhotos />
      <DefiDescription />
      <DefiFeatures />

      <DefiDownload />
      <Footer />
    </div>
  );
}

export default Defi;
