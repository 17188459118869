import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./singleUsecaseCall.css";

import logo from "../../assets/svg/logo-inner.svg";

function SingleUsecaseCall() {
  const { theme } = useTheme();

  return (
    <div
      className={`singleUsecaseCall ${theme === "light" ? "" : "dark-theme"}`}
    >
      <div className="singleUsecaseCall-container">
        <div className="singleUsecaseCall-title">Reserve Your Spot</div>
        <div className="singleUsecaseCall-action">
          <a href="/username" target="" rel="noreferrer">
            <div className="singleUsecaseCall-action-button">
              Request Verification
            </div>
          </a>
        </div>
      </div>
      <div className="singleUsecaseCall-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default SingleUsecaseCall;
