import React from "react";
import "./App.css";
import { useTheme } from "./ThemeProvider.js";
import Header from "./components/header/mainHeader.js";
import Hero from "./components/hero/mainHero.js";
import Statement from "./components/statement/mainStatement.js";
import Decentralized from "./components/decentralized/mainDecentralized.js";
import Features from "./components/features/mainFeatures.js";
import Usecases from "./components/usecases/mainUsecases.js";
import Powered from "./components/powered/mainPowered.js";
import Token from "./components/token/mainToken.js";
import Backed from "./components/backed/mainBacked.js";
import Cards from "./components/cards/mainCards.js";
import CallToAction from "./components/calltoaction/mainCallToAction.js";
import Footer from "./components/footer/mainFooter.js";

function App() {
  const { theme } = useTheme();

  return (
    <div className={`App ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <Hero />
      <Statement />
      <Decentralized />
      <Features />
      <Usecases />
      <Powered />
      <Token />
      <Backed />
      <Cards />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default App;
