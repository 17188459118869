import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./singleUsecasePhoto.css";

function SingleUsecasePhoto({ img, name }) {
  const { theme } = useTheme();

  return (
    <div
      className={`singleUsecasePhoto ${theme === "light" ? "" : "dark-theme"}`}
    >
      <div className="singleUsecasePhoto-container">
        <img src={img} alt={name} />
      </div>
    </div>
  );
}

export default SingleUsecasePhoto;
