import React, { useState, useEffect } from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainFooter.css";
import logoInner from "../../assets/svg/logo-inner.svg";
import writtenLogo from "../../assets/svg/logo-written.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faTelegram,
  faInstagram,
  faYoutube,
  faDiscord,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  const { theme } = useTheme();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 800);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`footer ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="footer-container">
        <div className="footer-left">
          <div className="footer-left-icons">
            <a href="/" target="" rel="noreferrer">
              <img
                src={logoInner}
                className="header-innerlogo"
                alt="Inner Logo"
              />
              <img
                src={writtenLogo}
                className="header-writtenlogo"
                alt="Written Logo"
              />
            </a>
          </div>
          <div className="footer-left-social">
            <a
              href="https://twitter.com/3vo_me"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faXTwitter} className="social-icon" />
            </a>
            <a
              href="https://t.me/Official_3VO"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTelegram} className="social-icon" />
            </a>
            <a
              href="https://www.instagram.com/3vo_me"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} className="social-icon" />
            </a>
            <a
              href="https://www.youtube.com/@3vo-me"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} className="social-icon" />
            </a>
            {/* <a
              href="https://discord.gg/FtgjyrR8SZ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faDiscord} className="social-icon" />
            </a> */}
            <a
              href="http://linkedin.com/company/3vo-me"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
            </a>
            <a
              href="https://www.tiktok.com/@3vo_me"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTiktok} className="social-icon" />
            </a>
          </div>
          {!isSmallScreen ? (
            <div className="footer-left-rights">
              © 2024 3VO S.A. All Rights Reserved.
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="footer-right">
          <div className="footer-right-section">
            <div className="footer-right-section-title">Project</div>
            <a href="/project" target="" rel="noreferrer">
              <div className="footer-right-section-link">About</div>
            </a>

            {/* <a
              href="https://docsend.com/view/wjc59cp7f44rzezm"
              target="_blank"
              rel="noreferrer"
            >
              <div className="footer-right-section-link">Tokenomics</div>
            </a> */}
            {/* <a href="">
              <div className="footer-right-section-link">Whitepaper</div>
            </a> */}
            <a
              href="https://docsend.com/view/2tgw63cdhw64uqu5"
              target="_blank"
              rel="noreferrer"
            >
              <div className="footer-right-section-link">Pitch Deck</div>
            </a>
            <a href="/project#roadmap" target="" rel="noreferrer">
              <div className="footer-right-section-link">Roadmap</div>
            </a>
            <a href="/earn" target="" rel="noreferrer">
              <div className="footer-right-section-link">Earn</div>
            </a>
            <a href="/quests" target="" rel="noreferrer">
              <div className="footer-right-section-link">Quests</div>
            </a>
            <a href="/presale" target="" rel="noreferrer">
              <div className="footer-right-section-link">Token Presale</div>
            </a>
          </div>
          <div className="footer-right-section">
            <div className="footer-right-section-title">Resources</div>
            <a
              href="https://www.linkedin.com/company/3vo-me/jobs/"
              target=""
              rel="noreferrer"
            >
              <div className="footer-right-section-link">Careers</div>
            </a>
            <a href="/tutorial" target="" rel="noreferrer">
              <div className="footer-right-section-link">Tutorials</div>
            </a>
            <a href="/privacy" target="" rel="noreferrer">
              <div className="footer-right-section-link">Privacy Policy</div>
            </a>
            <a href="/terms" target="" rel="noreferrer">
              <div className="footer-right-section-link">Terms of Use</div>
            </a>
            <a href="/terms" target="" rel="noreferrer">
              <div className="footer-right-section-link">Disclaimer</div>
            </a>
            {/* <a href="">
              <div className="footer-right-section-link">Blog</div>
            </a> */}
            {/* <a href="/" target="" rel="noreferrer">
              <div className="footer-right-section-link">Media</div>
            </a> */}

            {/* <a href="">
              <div className="footer-right-section-link">Partnerships</div>
            </a>
            <a href="">
              <div className="footer-right-section-link">Press Releases</div>
            </a> */}
            {/* <a href="/" target="" rel="noreferrer">
              <div className="footer-right-section-link">FAQs</div>
            </a> */}
            {/* <a href="">
              <div className="footer-right-section-link">Testimonials</div>
            </a> */}
            <a href="https://docs.3vo.me" target="_blank" rel="noreferrer">
              <div className="footer-right-section-link">Documentation</div>
            </a>
          </div>
          <div className="footer-right-section">
            <div className="footer-right-section-title">Forms</div>

            <a href="/contact" target="" rel="noreferrer">
              <div className="footer-right-section-link">Support</div>
            </a>
            <a href="/forms" target="" rel="noreferrer">
              <div className="footer-right-section-link">Partnerships</div>
            </a>
            <a href="/username" target="" rel="noreferrer">
              <div className="footer-right-section-link">Verification</div>
            </a>
          </div>
        </div>
      </div>
      {isSmallScreen ? (
        <div className="footer-rights">
          © 2024 3VO S.A. All Rights Reserved.
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Footer;
