import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mysteryBoxHero.css";

function MysteryBoxHero() {
  const { theme } = useTheme();

  return (
    <div className={`mysteryBox ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="mysteryBox-container">
        <div className="mysteryBox-title">
          <div>
            <div
              className="mysteryBox-title-title"
              style={{ color: "#ffffff" }}
            >
              <span className="specific-text">3VO</span> Mystery Box
            </div>
          </div>
          <div
            className="mysteryBox-title-subtitle"
            style={{ color: "#ffffff" }}
          >
            Score incredible rewards and connect with the top Web3 communities!
          </div>
          <div
            className="freesomCall-action"
            style={{
              paddingTop: "60px",
            }}
          >
            <a
              href="https://t.me/mysteryBox3voBot"
              target="_blank"
              rel="noreferrer"
            >
              <div className="freesomCall-action-button">Join Now</div>
            </a>
          </div>
          <div className="glare"></div>
        </div>
      </div>
    </div>
  );
}

export default MysteryBoxHero;
