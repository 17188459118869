import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainBacked.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

import skillfulD from "../../assets/partners/skillfulD.webp";
import skillfulL from "../../assets/partners/skillfulL.webp";
import stepD from "../../assets/partners/stepD.webp";
import stepL from "../../assets/partners/stepL.webp";
import wehodlD from "../../assets/partners/wehodlD.webp";
import wehodlL from "../../assets/partners/wehodlL.webp";
import threedotsD from "../../assets/partners/3dotsD.webp";
import threedotsL from "../../assets/partners/3dotsL.webp";
import triremeD from "../../assets/partners/TriremeD.webp";
import triremeL from "../../assets/partners/TriremeL.webp";
import grtinovaD from "../../assets/partners/gritnovaD.webp";
import grtinovaL from "../../assets/partners/gritnovaL.webp";
import badgeD from "../../assets/partners/thebadgeD.webp";
import badgeL from "../../assets/partners/thebadgeL.webp";
import corum8D from "../../assets/partners/corum8D.webp";
import corum8L from "../../assets/partners/corum8L.webp";
import nfmediaD from "../../assets/partners/nfmediaD.webp";
import nfmediaL from "../../assets/partners/nfmediaL.webp";
import finnovantD from "../../assets/partners/finnovantD.webp";
import finnovantL from "../../assets/partners/finnovantL.webp";
import redbellyD from "../../assets/partners/redbellyD.webp";
import redbellyL from "../../assets/partners/redbellyL.webp";
import cookieD from "../../assets/partners/cookie3D.webp";
import cookieL from "../../assets/partners/cookie3L.webp";
import avaD from "../../assets/partners/avalancheD.webp";
import avaL from "../../assets/partners/avalancheL.webp";
import bnbD from "../../assets/partners/bnbD.webp";
import bnbL from "../../assets/partners/bnbL.webp";
import cultureD from "../../assets/partners/clutureD.webp";
import cultureL from "../../assets/partners/clutureL.webp";

function Backed() {
  const { theme } = useTheme();

  const backedData = [
    {
      name: "Gritnova",
      logoD: grtinovaD,
      logoL: grtinovaL,
      link: "https://gritnova.com/",
    },
    {
      name: "Trireme Trading",
      logoD: triremeD,
      logoL: triremeL,
      link: "https://www.triremetrading.com/",
    },
    {
      name: "WeeHODL Capital",
      logoD: wehodlD,
      logoL: wehodlL,
      link: "https://weehodl.com/",
    },
    {
      name: "3dots",
      logoD: threedotsD,
      logoL: threedotsL,
      link: "https://3dots.ooo/",
    },
    {
      name: "Skillful Ai",
      logoD: skillfulD,
      logoL: skillfulL,
      link: "https://skillfulai.io/",
    },
    {
      name: "The Badge",
      logoD: badgeD,
      logoL: badgeL,
      link: "https://www.thebadge.xyz/",
    },
    {
      name: "Corum8",
      logoD: corum8D,
      logoL: corum8L,
      link: "https://corum8.com/",
    },
    {
      name: "Step in web3",
      logoD: stepD,
      logoL: stepL,
      link: "https://stepinweb3.com",
    },
    {
      name: "NF Media",
      logoD: nfmediaD,
      logoL: nfmediaL,
      link: "https://nfmedia.org/",
    },
    {
      name: "Finnovant",
      logoD: finnovantD,
      logoL: finnovantL,
      link: "https://www.finnovant.com/",
    },
    {
      name: "RedBelly",
      logoD: redbellyD,
      logoL: redbellyL,
      link: "https://www.redbelly.network/",
    },
    {
      name: "Cookie3",
      logoD: cookieD,
      logoL: cookieL,
      link: "https://www.cookie3.com/",
    },
    {
      name: "Avanalnce",
      logoD: avaD,
      logoL: avaL,
      link: "https://www.avax.network/",
    },
    {
      name: "Culture",
      logoD: cultureD,
      logoL: cultureL,
      link: "https://thecultureclub.us/",
    },
    {
      name: "BNB",
      logoD: bnbD,
      logoL: bnbL,
      link: "https://www.bnbchain.org/en",
    },
  ];
  return (
    <div className={`backed ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="backed-container">
        <div className="backed-title">Backed By</div>
        <div className="backed-icons">
          {backedData.map((item, index) => (
            <div className="backed-icon" key={index}>
              <a href={item.link} target="_blank" rel="noreferrer">
                <img
                  src={theme === "light" ? item.logoL : item.logoD}
                  alt={`Backed by ${item.name}`}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="backed-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default Backed;
