import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "../ThemeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./quests.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import logo from "../assets/svg/logo-inner-colored.svg";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  orderBy,
  startAfter,
  limit,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase"; 
import placeholderImage from "../assets/images/placeholder.png";
import rewardsData from "../assets/files/event.json";
import CountdownTimer from "./countdownTimer.js";
import QuestsRules from "./questsRules.js";

import doublePointImage from "../assets/images/doublepoint.webp";
import comingsoon from "../assets/images/comingsoon.webp";
import telegramvc from "../assets/images/telegramVc.webp";

function Quests() {
  const { theme } = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const searchContainerRef = useRef(null);
  const [searchCount, setSearchCount] = useState(0);

  const [loading, setLoading] = useState(true);

  const [foundMatch, setFoundMatch] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedRank, setSelectedRank] = useState(null);

  const [scoreF, setScoreF] = useState(0);
  const [scoreL, setScoreL] = useState(0);
  const [scoreP, setScoreP] = useState(0);
  const [scoreE, setScoreE] = useState(0);
  const [scoreS, setScoreS] = useState(0);
  const [selectedScore, setSelectedScore] = useState(0);

  const [specialReward, setSpecialReward] = useState(false);

  const [specialRewardAmount, setSpecialRewardAmount] = useState(0);

  const [rankData, setRankData] = useState([]);
  const [rewards, setRewards] = useState({});

  const [mvpName, setMvpName] = useState("");
  const [mvpPoints, setMvpPoints] = useState("");
  const [mvpPicture, setMvpPicture] = useState(placeholderImage);

  const [lastSearch, setLastSearch] = useState("");

  const formatDateToUTC = (date) => {
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const currentDate = formatDateToUTC(new Date());

  const special = [
    {
      name: "Telegram Voice Chat",
      image: telegramvc,
      url: "https://t.me/Official_3VO",
      dates: ["9-06-2024"],
    },
    {
      name: "Double Points Sunday",
      image: doublePointImage,
      url: "https://t.me/channel_3vo_me/29",
      dates: ["09-06-2024"],
    },

    {
      name: "Coming Soon",
      image: comingsoon,
      url: "",
      dates: ["08-06-2024"],
    },
  ];

  const updateCheckedField = async (userId) => {
    try {
      const userDocRef = doc(firestore, "users", userId);
      await updateDoc(userDocRef, { Checked: true }, { merge: true });
    } catch (error) {
      console.error("Error updating Checked field:", error);
    }
  };

  useEffect(() => {
    let timeoutId;

    const fetchData = async () => {
      if (searchTerm.trim() === "") {
        setSearchResults([]);
        setShowResults(false);
        return;
      }

      if (searchTerm == lastSearch) {
        return;
      }

      setLastSearch(searchTerm);

      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      const nicknameQuery = query(
        collection(firestore, "users"),
        where("nicknameLowercase", ">=", lowerCaseSearchTerm),
        where("nicknameLowercase", "<=", lowerCaseSearchTerm + "\uf8ff")
      );

      const usernameQuery = query(
        collection(firestore, "users"),
        where("usernameLowercase", ">=", lowerCaseSearchTerm),
        where("usernameLowercase", "<=", lowerCaseSearchTerm + "\uf8ff")
      );

      try {
        const nicknameQuerySnapshot = await getDocs(nicknameQuery);
        const usernameQuerySnapshot = await getDocs(usernameQuery);

        const uniqueUsers = new Map();

        nicknameQuerySnapshot.forEach((doc) => {
          uniqueUsers.set(doc.id, doc.data());
        });

        usernameQuerySnapshot.forEach((doc) => {
          uniqueUsers.set(doc.id, doc.data());
        });

        const mergedUsers = Array.from(uniqueUsers.values()).slice(0, 4);

        setSearchResults(mergedUsers);
        setShowResults(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleSearch = () => {
      if (searchResults) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        setShowResults(false);
        setSearchResults(["Loading"]);
        timeoutId = setTimeout(fetchData, 100);
      } else if (searchResults == null) {
        setSearchResults(["No results"]);
        setShowResults(false);
      }
    };

    const lastSearchTime = localStorage.getItem("lastSearchTime");
    if (lastSearchTime) {
      const lastHourTimestamp = new Date(lastSearchTime).getTime();
      const currentTimestamp = new Date().getTime();
      const hourDifference =
        (currentTimestamp - lastHourTimestamp) / (1000 * 60 * 60);

      if (hourDifference >= 1) {
        // Reset search count if more than an hour has passed since the last search
        localStorage.removeItem("lastSearchTime");
        localStorage.removeItem("searchCount");
      } else if (searchCount >= 10) {
        setShowResults(false);
        setSearchResults(["Too many requests, please try again later"]);
        return;
      }
    }

    handleSearch();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchTerm, searchCount]);

  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShowResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectItem = (result) => {
    setSearchTerm("");
    setShowResults(false);
    setSelectedItem(result);
  };

  useEffect(() => {
    const fetchDataForSelectedItem = async () => {
      if (selectedItem) {
        const userId = String(selectedItem.userId);
        try {
          const scoreDocRef = doc(firestore, "scores", userId);
          const scoreDocSnap = await getDoc(scoreDocRef);
          await updateCheckedField(userId);

          if (scoreDocSnap.exists()) {
            const scoreData = scoreDocSnap.data();

            const userScore = parseFloat(scoreData.totalScore) || 0;
            const parsedScores = {
              scoreE: parseFloat(scoreData.earnedScore) || 0,
              scoreF: parseFloat(scoreData.followersScore) || 0,
              scoreP: parseFloat(scoreData.postsScore) || 0,
              scoreL: parseFloat(scoreData.likesScore) || 0,
              scoreS: parseFloat(scoreData.specialPoints) || 0,
            };

            setScoreE(parsedScores.scoreE);
            setScoreF(parsedScores.scoreF);
            setScoreP(parsedScores.scoreP);
            setScoreL(parsedScores.scoreL);
            setScoreS(parsedScores.scoreS);
            setSelectedScore(userScore.toFixed(0));
            setSelectedImage(selectedItem.avatar);
            setSelectedRank(scoreData.rank);

            if (scoreData.code && /^sp\d+$/i.test(scoreData.code)) {
              const tokens = parseInt(scoreData.code.substring(2), 10);
              setSpecialReward(true);
              setSpecialRewardAmount(tokens);
            } else {
              setSpecialReward(false);
            }
          } else {
            console.log("Score document does not exist for user:", userId);
          }
        } catch (error) {
          console.error("Error fetching user's score:", error);
        }
      }
    };

    fetchDataForSelectedItem();
  }, [selectedItem, firestore]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = [];
        let lastUserDoc = null;

        while (true) {
          const querySnapshot = await getDocs(
            query(
              collection(firestore, "users"),
              orderBy("userId"),
              startAfter(lastUserDoc),
              limit(1000)
            )
          );

          if (querySnapshot.empty) break;

          querySnapshot.forEach((doc) => {
            users.push(doc.data());
          });

          lastUserDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        }

        const scoresQuerySnapshot = await getDocs(
          collection(firestore, "scores")
        );

        const rewards = rewardsData.reduce((acc, reward) => {
          acc[reward.rank] = reward;
          return acc;
        }, {});

        const scores = scoresQuerySnapshot.docs.map((doc) => doc.data());

        const mergedData = users.map((user) => {
          const userId = parseInt(user.userId);
          const score = scores.find((s) => parseInt(s.userId) === userId);

          return {
            userId: userId,
            rank: score ? score.rank : null,
            totalScore: score ? parseInt(score.totalScore) : null,
            name: user.nickname,
            username: user.username,
            avatar: user.avatar,
          };
        });

        const filteredData = mergedData.filter((user) => user.rank !== null);
        filteredData.sort((a, b) => a.rank - b.rank);

        // Store data and timestamp in localStorage
        const currentTime = new Date().toISOString();
        localStorage.setItem("rankData", JSON.stringify(filteredData));
        localStorage.setItem("mvpName", filteredData[0].name);
        localStorage.setItem("mvpPoints", filteredData[0].totalScore);
        localStorage.setItem("mvpPicture", filteredData[0].avatar);
        localStorage.setItem("rewards", JSON.stringify(rewards));
        localStorage.setItem("dataTimestamp", currentTime);

        setLoading(false);
        setRankData(filteredData);
        setMvpName(filteredData[0].name);
        setMvpPoints(filteredData[0].totalScore);
        setMvpPicture(filteredData[0].avatar);
        setRewards(rewards);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const isDataValid = (timestamp) => {
      const currentTime = new Date();
      const dataTime = new Date(timestamp);

      return (
        currentTime.getFullYear() === dataTime.getFullYear() &&
        currentTime.getMonth() === dataTime.getMonth() &&
        currentTime.getDate() === dataTime.getDate() &&
        currentTime.getHours() === dataTime.getHours()
      );
    };

    // Retrieve data from localStorage if available and valid
    const storedRankData = localStorage.getItem("rankData");
    const storedMvpName = localStorage.getItem("mvpName");
    const storedMvpPoints = localStorage.getItem("mvpPoints");
    const storedMvpPicture = localStorage.getItem("mvpPicture");
    const storedRewards = localStorage.getItem("rewards");
    const storedTimestamp = localStorage.getItem("dataTimestamp");

    if (
      storedRankData &&
      storedMvpName &&
      storedMvpPoints &&
      storedMvpPicture &&
      storedRewards &&
      storedTimestamp &&
      isDataValid(storedTimestamp)
    ) {
      setLoading(false);
      setRankData(JSON.parse(storedRankData));
      setMvpName(storedMvpName);
      setMvpPoints(storedMvpPoints);
      setMvpPicture(storedMvpPicture);
      setRewards(JSON.parse(storedRewards));
    } else {
      fetchData();
    }
  }, []);

  const calculate3VO = (rank) => {
    const reward = rewards[rank];
    return reward ? reward.tokens : "0";
  };

  const calculateUSDT = (rank) => {
    const reward = rewards[rank];
    return reward ? reward.usdt : "0";
  };

  const calculate3VOSelected = (rank) => {
    if (selectedScore === 0) {
      return 0;
    }
    const reward = rewards[rank];
    return reward ? reward.tokens : "0";
  };

  const calculateUSDTSelected = (rank) => {
    if (selectedScore === 0) {
      return 0;
    }
    const reward = rewards[rank];
    return reward ? reward.usdt : "0";
  };

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <div className="embed">
        <div className="embed-container">
          <div className="embed-title">
            <img src={logo} alt="3vo_logo"></img>
            Quests <span className="specific-text">Season 1</span>
          </div>

          <div className="quests-main">
            <div className="quests-special-point">
              {special.map((item) => (
                <div
                  className={`quests-special-point-item ${
                    item.dates.includes(currentDate)
                      ? "quests-special-point-item-current"
                      : "quests-special-point-item-inactive"
                  }`}
                  key={item.name}
                >
                  {item.url ? (
                    <a href={item.url}>
                      <img src={item.image} alt={item.name} />
                    </a>
                  ) : (
                    <img src={item.image} alt={item.name} />
                  )}
                </div>
              ))}
            </div>
            <div className="quests-search-container" ref={searchContainerRef}>
              <div className="search-input-container">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input
                  type="text"
                  placeholder="Search using Username or Name"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  className="search-input"
                />
              </div>
              {showResults && searchResults.length > 0 ? (
                <div className="search-results">
                  {searchResults.includes("Loading") ? (
                    <div className="search-item">
                      <div>
                        <p>Loading...</p>
                      </div>
                    </div>
                  ) : (
                    searchResults.map((result, index) => (
                      <div
                        key={index}
                        className="search-item"
                        onClick={() => handleSelectItem(result)}
                      >
                        <div>
                          <img
                            src={result.avatar}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = placeholderImage;
                            }}
                          />
                          <p className="search-item-name">{result.username}</p>
                          <p className="search-item-username">
                            @{result.nickname}
                          </p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              ) : showResults ? (
                <div className="search-results">
                  <div>
                    <div className="search-item">
                      <div>
                        <p> No Results Found</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {!selectedItem ? (
              <div className="quests-search-results-container">
                {searchCount < 24 ? (
                  <>Start by searching for user</>
                ) : (
                  <>Too many requests, please try again later</>
                )}
              </div>
            ) : (
              <>
                {!specialReward ? (
                  <div className="quests-search-results-container-filled">
                    <div className="quests-results-main">
                      <div className="quests-results-main-top">
                        <div className="quests-results-image-container">
                          <img
                            src={selectedImage}
                            alt={selectedItem.nickname}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = placeholderImage;
                            }}
                            className="quests-results-image"
                          />
                        </div>
                        <div className="quests-results-name-container">
                          {selectedItem.nickname}
                        </div>
                      </div>
                      <div className="quests-selected-points-container">
                        <div className="quests-results-main-rank">
                          <div className="quests-results-rank-value">
                            #{selectedRank}
                          </div>
                          <div className="quests-results-points-value">
                            {selectedScore}
                          </div>
                        </div>
                        <div className="quests-results-main-points">
                          <div className="quests-results-points-title">
                            $3VO Reward
                          </div>
                          <div className="quests-results-reward-value">
                            {calculate3VOSelected(
                              selectedRank
                            ).toLocaleString()}
                          </div>
                        </div>
                        <div className="quests-results-main-points">
                          <div className="quests-results-points-title">
                            USDT Reward
                          </div>
                          <div className="quests-results-reward-value">
                            $
                            {calculateUSDTSelected(
                              selectedRank
                            ).toLocaleString()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="quests-results-other">
                      <div className="quests-results-other-row">
                        <div className="quests-results-other-item">
                          <div className="quests-results-other-item-title">
                            Followers
                          </div>
                          <div className="quests-results-other-item-value">
                            {(scoreF / 4).toLocaleString()}
                          </div>
                          <div className="quests-results-other-item-points">
                            {scoreF.toLocaleString()} pts
                          </div>
                        </div>
                        <div className="quests-results-other-item">
                          <div className="quests-results-other-item-title">
                            Transactions
                          </div>
                          <div className="quests-results-other-item-value">
                            ${scoreE.toFixed(2)}
                          </div>
                          <div className="quests-results-other-item-points">
                            {scoreE.toFixed(0)} pts
                          </div>
                        </div>

                        <div className="quests-results-other-item">
                          <div className="quests-results-other-item-title">
                            Posts
                          </div>
                          <div className="quests-results-other-item-value">
                            {(scoreP / 10).toLocaleString()}
                          </div>
                          <div className="quests-results-other-item-points">
                            {scoreP.toLocaleString()} pts
                          </div>
                        </div>
                        <div className="quests-results-other-item">
                          <div className="quests-results-other-item-title">
                            Likes
                          </div>
                          <div className="quests-results-other-item-value">
                            {scoreL.toLocaleString()}
                          </div>
                          <div className="quests-results-other-item-points">
                            {scoreL} pts
                          </div>
                        </div>
                        <div className="quests-results-other-item">
                          <div className="quests-results-other-item-title">
                            Special
                          </div>
                          <div className="quests-results-other-item-value">
                            {scoreS.toLocaleString()}
                          </div>
                          <div className="quests-results-other-item-points">
                            {scoreS} pts
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="quests-search-results-container-filled">
                    <div className="quests-results-main-sp">
                      <div className="quests-results-main-top-sp">
                        <div className="quests-results-image-container">
                          <img
                            src={selectedImage}
                            alt={selectedItem.nickname}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = placeholderImage;
                            }}
                            className="quests-results-image"
                          />
                        </div>
                        <div className="quests-results-name-container">
                          {selectedItem.nickname}
                        </div>
                      </div>
                      <div className="quests-selected-points-container">
                        <div className="quests-results-main-points">
                          <div className="quests-results-points-title">
                            $3VO Reward
                          </div>
                          <div className="quests-results-reward-value">
                            {specialRewardAmount.toLocaleString()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="quest-title">Leaderboard</div>
          <div className="timer-cointainer">
            <CountdownTimer targetDate="2024-06-29T00:00:00Z" />
          </div>
          <div className="quests-leaderboard-container">
            {loading ? (
              <div className="quests-leaderboard-loading">Loading ...</div>
            ) : (
              <>
                <div className="quests-leaderboard-mvp">
                  <div className="quests-leaderboard-mvp-title">Top User</div>
                  <div className="quests-leaderboard-mvp-details">
                    <img
                      src={mvpPicture || placeholderImage}
                      alt={mvpName}
                      className="user-image"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = placeholderImage;
                      }}
                    />

                    <div className="quests-leaderboard-mvp-name">{mvpName}</div>
                    <div className="quests-leaderboard-mvp-points">
                      {mvpPoints}
                    </div>
                  </div>
                </div>
                <table className="quests-leaderboard-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Points</th>
                      <th>$3VO</th>
                      <th>USDT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rankData.slice(0, 10).map((user, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "row-even" : "row-odd"}
                      >
                        <td>{user.rank}.</td>
                        <td>
                          <img
                            src={user.avatar || placeholderImage}
                            alt={user.name}
                            className="user-image"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = placeholderImage;
                            }}
                          />
                          @{user.name}
                        </td>

                        <td>{user.totalScore.toLocaleString()}</td>
                        <td>{calculate3VO(user.rank).toLocaleString()}</td>
                        <td>{calculateUSDT(user.rank)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
          <div className="quest-time-update">
            * all data updates on an hourly interval
          </div>
          <QuestsRules />
        </div>
        <div className="embed-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Quests;
