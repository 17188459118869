import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./tokenStatement.css";

function TokenStatement() {
  const { theme } = useTheme();

  return (
    <div className={`tokenStatement ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="tokenStatement-container">
        <div className="tokenStatement-title">
          3VO token lies at the heart of the 3vo ecosystem
        </div>
      </div>
    </div>
  );
}

export default TokenStatement;
