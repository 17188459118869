import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./projectHero.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function ProjectHero() {
  const { theme } = useTheme();

  return (
    <div
      id="about"
      className={`projectHero ${theme === "light" ? "" : "dark-theme"}`}
    > 
      <div className="projectHero-container">
        <div className="projectHero-title">
          <div>
            <div className="projectHero-title-title">About</div>
          </div>
          <div className="projectHero-title-subtitle">
            The current system for creating, consuming, and transacting value is
            unfair and inefficient. Web2 platforms controlled by tech giants
            like Google and Amazon monopolize user data, while financial
            transactions rely on intermediaries like Visa and Mastercard. This
            concentration of power leads to censorship and manipulation. <br />
            <br /> Web3 technology offers more user control and
            decentralization, but faces adoption challenges due to complexity
            and scalability issues. 3vo addresses these challenges by bridging
            Web2 and Web3, aiming for a fairer and more efficient digital
            landscape. By integrating social media with crypto payments,
            tokenization and no-code web3 creator, 3vo enables a permissionless
            global market of digital value where 5.18B people around the globe
            with internet access can create and transact freely with the minimal
            amount of intermediaries.
          </div>
        </div>
        <div className="projectHero-card-container">
          <div className="projectHero-card">
            <div className="projectHero-card-title">
              <div className="projectHero-card-title-main">Vision</div>
              <div className="projectHero-card-title-sub">
                A Global Permissionless Marketplace of Value.
              </div>
            </div>
            <div className="projectHero-card-description">
              Decentralization and User Empowerment <br /> Democratization of
              Opportunities <br />
              Enhanced Privacy and Security <br />
              Collaborative and Interconnected Ecosystem <br />
              Global Adoption and Impact
            </div>
          </div>
          <div className="projectHero-card">
            <div className="projectHero-card-title">
              <div className="projectHero-card-title-main">Mission</div>
              <div className="projectHero-card-title-sub">
                Empowering every individual, business and organization with web3
                tools
              </div>
            </div>
            <div className="projectHero-card-description">
              We are striving for a future where Web3 technologies revolutionize
              the digital landscape, transforming the way individuals,
              businesses, and organizations interact and engage online.
            </div>
          </div>
        </div>
      </div>
      <div className="projectHero-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default ProjectHero;
