import React from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import MysteryBoxHero from "../mysterybox/MysteryBoxHero/mysteryBoxHero.js";
import MysteryBoxMain from "../mysterybox/mysteryBoxMain/mysteryBoxMain.js";
import HowToJoin from "../mysterybox/howToJoin/howToJoin.js";
import MysteryCall from "../mysterybox/mysteryCall/mysteryCall.js";
import MysteryDetails from "../mysterybox/details/mysteryDetail.js";
import MysteryCall2 from "../mysterybox/call/mysteryCall2.js";

function MysteryBoxLanding() {
  const { theme } = useTheme();

  document.title = "3VO | Mystery Box";

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <MysteryBoxHero />
      <MysteryBoxMain />
      <HowToJoin />
      <MysteryCall />
      <MysteryDetails />
      <MysteryCall2 />
      <Footer />
    </div>
  );
}

export default MysteryBoxLanding;
