import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./usecasesCards.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

import tasty from "../../assets/images/tastypizza.webp";
import gab from "../../assets/images/fashiongab.webp";
import maya from "../../assets/images/maya.webp";
import tucker from "../../assets/images/tucker.webp";
import player from "../../assets/images/playerone.webp";
import fitness from "../../assets/images/fitness.webp";

function UsecasesCards() {
  const { theme } = useTheme();

  return (
    <div className={`usecasesCards ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="usecasesCards-container">
        <div className="usecasesCards-card-container-h">
          <div className="usecasesCards-card-h">
            <div className="usecasesCards-card-text-h">
              <div className="usecasesCards-card-text-title-h">
                For Everyone
              </div>
              <div className="usecasesCards-card-text-description-h">
                Whether you're a creator, brand, community, developer, or
                entrepreneur, 3vo has a tailored solution just for you. With a
                diverse array of tools and services, 3vo empowers individuals
                and organizations alike to thrive in the decentralized
                landscape.
              </div>
            </div>
            <div className="usecasesCards-card-image-h"></div>
          </div>
        </div>
        <div className="usecasesCards-card-container">
          <a
            className="usecasesCards-card"
            href="/usecases/restaurant"
            target=""
            rel="noreferrer"
          >
            <div className="usecasesCards-card-image">
              <img src={tasty} alt="3VO Tasty Pizza" />
              <a
                href="/usecases/restaurant"
                target=""
                rel="noreferrer"
                className="usecasesCards-card-image-learn"
              >
                Learn More
              </a>
            </div>
            <div className="usecasesCards-card-text">
              <div className="usecasesCards-card-text-title">Tasty Pizza</div>
              <div className="usecasesCards-card-text-description">
                Bring your business to web3. Deliver content, engage customers
                with interactive Dapps. Accept crypto payments and create
                loyalty tokens that can be exchanged for goods and services.
              </div>
            </div>
          </a>
          <a
            className="usecasesCards-card"
            href="/usecases/fashion"
            target=""
            rel="noreferrer"
          >
            <div className="usecasesCards-card-image">
              <img src={gab} alt="3VO Fashion Gabriela" />
              <a
                href="/usecases/fashion"
                target=""
                rel="noreferrer"
                className="usecasesCards-card-image-learn"
              >
                Learn More
              </a>
            </div>
            <div className="usecasesCards-card-text">
              <div className="usecasesCards-card-text-title">
                Fashion Gabriela
              </div>
              <div className="usecasesCards-card-text-description">
                Level up your ventures and build the next billion-dollar idea.
                Present your unique project, fundraise, and put your idea into
                motion on one unified platform.
              </div>
            </div>
          </a>
        </div>
        <div className="usecasesCards-card-container">
          <a
            className="usecasesCards-card"
            href="/usecases/creator"
            target=""
            rel="noreferrer"
          >
            <div className="usecasesCards-card-image">
              <img src={maya} alt="3VO The Only Maya" />
              <a
                href="/usecases/creator"
                target=""
                rel="noreferrer"
                className="usecasesCards-card-image-learn"
              >
                Learn More
              </a>
            </div>
            <div className="usecasesCards-card-text">
              <div className="usecasesCards-card-text-title">The Only Maya</div>
              <div className="usecasesCards-card-text-description">
                Create more than just content. Deliver unique experiences that
                best suit your niche. Earn instantly with built-in monetization.
                Your revenue remains fully yours.
              </div>
            </div>
          </a>
          <a
            className="usecasesCards-card"
            href="/usecases/reporter"
            target=""
            rel="noreferrer"
          >
            <div className="usecasesCards-card-image">
              <img src={tucker} alt="3VO Tucker Reports" />
              <a
                // href="/usecases/reporter"
                // target=""
                // rel="noreferrer"
                className="usecasesCards-card-image-learn"
              >
                Learn More
              </a>
            </div>
            <div className="usecasesCards-card-text">
              <div className="usecasesCards-card-text-title">
                Tucker Reports
              </div>
              <div className="usecasesCards-card-text-description">
                Lead with impact using 3vo. Turn your name into a brand. Issue
                fan tokens to your followers. Foster a community-driven movement
                via DAO tools and others.
              </div>
            </div>
          </a>
        </div>
        <div className="usecasesCards-card-container">
          <a
            className="usecasesCards-card"
            href="/usecases/streamer"
            target=""
            rel="noreferrer"
          >
            <div className="usecasesCards-card-image">
              <img src={player} alt="3VO The Only Maya" />
              <a
                href="/usecases/streamer"
                target=""
                rel="noreferrer"
                className="usecasesCards-card-image-learn"
              >
                Learn More
              </a>
            </div>
            <div className="usecasesCards-card-text">
              <div className="usecasesCards-card-text-title">PlayerOne</div>
              <div className="usecasesCards-card-text-description">
                Bring your gaming to web3. Easily create and trade in-game
                assets, organize events, stream with crypto tips, and connect
                with your community—all within a seamless social media
                experience.
              </div>
            </div>
          </a>
          <a
            className="usecasesCards-card"
            href="/usecases/gym"
            target=""
            rel="noreferrer"
          >
            <div className="usecasesCards-card-image">
              <img src={fitness} alt="3VO Tucker Reports" />
              <a
                href="/usecases/gym"
                target=""
                rel="noreferrer"
                className="usecasesCards-card-image-learn"
              >
                Learn More
              </a>
            </div>
            <div className="usecasesCards-card-text">
              <div className="usecasesCards-card-text-title">Fitness Flex</div>
              <div className="usecasesCards-card-text-description">
                Transform your fitness studio with web3 technology. Easily
                tokenize memberships and organize classes. Enhance your fitness
                journey with streaming workouts and seamless member interaction.
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="usecasesCards-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default UsecasesCards;
