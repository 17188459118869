import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./projectAdvisors.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

import glenn from "../../assets/team/glen.webp";
import ali from "../../assets/team/ali.webp";
import ibrahim from "../../assets/team/ibrahim.webp";
import stephan from "../../assets/team/stephan.webp";
import jannik from "../../assets/team/jan.webp";
import edouard from "../../assets/team/ed.webp";
import kellen from "../../assets/team/kellen.webp";
import roaa from "../../assets/team/roaa.webp";
import tomer from "../../assets/team/tomer.webp";
import tal from "../../assets/team/tal.webp";

function ProjectAdvisors() {
  const { theme } = useTheme();

  const Advisors = [
    {
      name: "Glenn Grant",
      title: "Institutional",
      picture: glenn,
      twitter: "",
      linkedin: "",
      disc: "Former Director at Kraken, Saxo Bank. Over 30 years in both traditional and fintech derivatives across EU, Middle East, Asia and the United States.",
    },
    {
      name: "Ali Aoun",
      title: "Compliance",
      picture: ali,
      twitter: "",
      linkedin: "",
      disc: "Global Compliance expert currently at Wells Fargo, Former JP Morgan, Rabobank, Department of State, US Army.",
    },
    {
      name: "Ibrahim Ezzedine",
      title: "Strategy / Education",
      picture: ibrahim,
      twitter: "",
      linkedin: "",
      disc: "Founder of Gritnova, a global campus equipping young professionals around the world with cutting-edge in-demand skills. Previous projects with the United Nations and the World Bank.",
    },
    {
      name: "Stephan Milacek",
      title: "Hiring Advisor",
      picture: stephan,
      twitter: "",
      linkedin: "",
      disc: "Former CEO of cryptojobs.com and one of the core team members in DAOMaker. Currently in charge of Step in – talent acquisition company.",
    },
    {
      name: "Jannik Rohloff",
      title: "Partnerships Advisor",
      picture: jannik,
      twitter: "",
      linkedin: "",
      disc: "Founder of Nfmedia.org, a platform dedicated to helping best web3 entrepreneurs building up their innovative projects.",
    },
    {
      name: "Edouard Cooper",
      title: "Fundraising Advisor",
      picture: edouard,
      twitter: "",
      linkedin: "",
      disc: "Web3 Fundraiser, Partnership Manager in Finance, Banking and Crypto industry. Former Swiss Private Banker. COO of Athlerse.",
    },
    {
      name: "Alex Roa",
      title: "Influencers",
      picture: roaa,
      twitter: "",
      linkedin: "",
      disc: "CEO - The Culture Club and blitzpay. he manages and facilitates dealflow and payments between over 15,000 influencers globally.",
    },
    {
      name: "Kellen Cooper",
      title: "KOLs",
      picture: kellen,
      twitter: "",
      linkedin: "",
      disc: "Crypto promoter with a network of 200+ KOLs, advisor to NFT projects.",
    },
    {
      name: "Tomer Nuni",
      title: "Strategy / Fundraising",
      picture: tomer,
      twitter: "",
      linkedin: "",
      disc: "Currently leads the investment arm of ChainGPT Labs. CMO at Kima Finance. Writer for Cointelegraph and Forbes. Played a key role in incubating ChainGPT..",
    },
    {
      name: "Tal Cohen",
      title: "Institutional",
      picture: tal,
      twitter: "",
      linkedin: "",
      disc: "Managing Director at Kraken Digital Asset Exchange US, CEO Payward Ventures Inc.",
    },
  ];

  // Split the advisors into chunks of 2
  const chunks = Advisors.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <div
      id="advisors"
      className={`projectAdvisors ${theme === "light" ? "" : "dark-theme"}`}
    >
      <div className="projectAdvisors-container">
        <div className="projectAdvisors-title">
          <div>
            <div className="projectAdvisors-title-title">Advisors</div>
          </div>
        </div>
        <div className="projectAdvisors-team">
          {chunks.map((chunk, rowIndex) => (
            <div key={rowIndex} className="projectAdvisors-row">
              {chunk.map((member, colIndex) => (
                <div key={colIndex} className="projectAdvisors-member">
                  <div className="projectAdvisors-member-photo">
                    <img src={member.picture} alt={member.name} />
                  </div>
                  <div className="projectAdvisors-member-text">
                    <div className="projectAdvisors-member-text-title">
                      <div className="projectAdvisors-member-text-title-name">
                        {member.name}
                      </div>
                      <div className="projectAdvisors-member-text-title-title">
                        {member.title}
                      </div>
                    </div>
                    <div className="projectAdvisors-member-text-desc">
                      {member.disc}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="projectAdvisors-background">
        <img src={logo} alt="3vo-superapp" />
      </div>
    </div>
  );
}

export default ProjectAdvisors;
