import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./freedomCall.css";

function FreedomCall() {
  const { theme } = useTheme();

  return (
    <div className={`freesomCall ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="freesomCall-container">
        <div className="freesomCall-title">
          Explore Endless Possibilities
        </div>
        <div className="freesomCall-action">
          <a href="https://docs.3vo.me" target="_blank" rel="noreferrer">
            <div className="freesomCall-action-button">Documentation</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FreedomCall;
