import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainFeatures.css";
import social from "../../assets/images/Social.webp";

import chat from "../../assets/images/chat.webp";
import feed from "../../assets/images/tokenize.webp";
import tip from "../../assets/images/tip.webp";
import create from "../../assets/images/create.webp";

function Features() {
  const { theme } = useTheme();

  return (
    <div className={`features ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="features-first-container">
        <div className="features-feature-main">
          <div className="features-feature-main-text">
            <div className="features-feature-main-text-title">
              Social From The Ground Up
            </div>
            <div className="features-feature-main-text-description">
              Use the best social media functions you love. Enjoy content from
              people and brands that matter most to you, in one customizable
              feed.
            </div>
          </div>
          <div className="features-feature-main-image">
            <img src={social} alt="3VO Social"></img>
          </div>
        </div>
      </div>
      <div className="features-second-container">
        <div className="features-feature-second-set">
          <div className="features-feature-second">
            <div className="features-feature-second-text">
              <div className="features-feature-second-text-title">Build</div>
              <div className="features-feature-second-text-description">
                Build social applications with user-friendly no-code tools. Turn
                your profile page into experiences.
              </div>
            </div>
            <div className="features-feature-second-image">
              <img src={create} alt="3VO 3eator"></img>
            </div>
          </div>
          <div className="features-feature-second">
            <div className="features-feature-second-text">
              <div className="features-feature-second-text-title">Tokenize</div>
              <div className="features-feature-second-text-description">
                Transform your value into a token with a user-friendly tool to
                create any token with no coding required and powered by a
                library of smart contracts.
              </div>
            </div>
            <div className="features-feature-second-image">
              <img src={feed} alt="3VO Feed"></img>
            </div>
          </div>
        </div>
        <div className="features-feature-second-set">
          <div className="features-feature-second">
            <div className="features-feature-second-text">
              <div className="features-feature-second-text-title">Monetize</div>
              <div className="features-feature-second-text-description">
                Earn directly with tipping and subscriptions. Own tokenized
                content or create your own token in seconds.
              </div>
            </div>
            <div className="features-feature-second-image">
              <img src={tip} alt="3VO Tip"></img>
            </div>
          </div>

          <div className="features-feature-second">
            <div className="features-feature-second-text">
              <div className="features-feature-second-text-title">
                Communicate
              </div>
              <div className="features-feature-second-text-description">
                Talk with friends via text, voice, or video. Send / receive
                money or share and invite to activities directly from the chat.
              </div>
            </div>
            <div className="features-feature-second-image">
              <img src={chat} alt="3VO Chat"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
