import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./dappsCall.css";

function DappsCall() {
  const { theme } = useTheme();

  return (
    <div className={`dappsCall ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="dappsCall-container">
        <div className="dappsCall-title">Learn More About 3VO Dapps</div>
        <div className="dappsCall-action">
          <a href="https://docs.3vo.me" target="_blank" rel="noreferrer">
            <div className="dappsCall-action-button">Documentation</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default DappsCall;
