import React, { useState } from "react";
import { useTheme } from "../ThemeProvider.js";
import "./embed.css";
import "./tutorial.css";
import logo from "../assets/svg/logo-inner-colored.svg";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";

import step1 from "../assets/images/step1.webp";
import step2 from "../assets/images/step2.webp";
import step3 from "../assets/images/step3.webp";

function Tutorial() {
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [expandedIndex, setExpandedIndex] = useState(-1); // Track which item is expanded
  const [currentStep, setCurrentStep] = useState(0); // Track current step index

  document.title = "3VO | Tutorials";

  const nextStep = () => {
    if (currentStep < tutorialItems[expandedIndex].steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const toggleItem = (index) => {
    if (expandedIndex === index) {
      // If clicking on the title of the expanded item, toggle collapse
      setExpandedIndex(-1);
    } else {
      // If clicking on a different item or the title of a collapsed item, expand
      setExpandedIndex(index);
      setCurrentStep(0); // Reset current step to the first step when expanding
    }
  };

  const handleClickInsideExpandedArea = (event) => {
    event.stopPropagation(); // Prevent click event from bubbling up to parent
  };

  const tutorialItems = [
    {
      turorial: false,
      title: "3VO Account",
      steps: [],
    },
    // {
    //   turorial: true,
    //   title: "How To Create A 3VO Account",
    //   steps: [
    //     {
    //       description: "1. Open 3VO App menu",
    //       imageSrc: step1,
    //       number: "1",
    //     },
    //     {
    //       description: "2. Click on settings",
    //       imageSrc: step2,
    //       number: "2",
    //     },
    //     {
    //       description: "3. Press on Delete Account and confirm deleting",
    //       imageSrc: step3,
    //       number: "3",
    //     },
    //   ],
    // },
    {
      turorial: true,
      title: "How To Delete Your 3VO Account",
      steps: [
        {
          description: "1. Open 3VO App menu",
          imageSrc: step1,
          number: "1",
        },
        {
          description: "2. Click on settings",
          imageSrc: step2,
          number: "2",
        },
        {
          description: "3. Press on Delete Account and confirm deleting",
          imageSrc: step3,
          number: "3",
        },
      ],
    },
    // {
    //   turorial: true,
    //   title: "How To Reserve your 3VO username",
    //   steps: [
    //     {
    //       description: "1. Open 3VO App menu",
    //       imageSrc: step1,
    //       number: "1",
    //     },
    //     {
    //       description: "2. Click on settings",
    //       imageSrc: step2,
    //       number: "2",
    //     },
    //     {
    //       description: "3. Press on Delete Account and confirm deleting",
    //       imageSrc: step3,
    //       number: "3",
    //     },
    //   ],
    // },
    // {
    //   turorial: false,
    //   title: "Content",
    //   steps: [],
    // },
    // {
    //   turorial: true,
    //   title: "How To Post text on 3VO",
    //   steps: [
    //     {
    //       description: "1. Open 3VO App menu",
    //       imageSrc: step1,
    //       number: "1",
    //     },
    //     {
    //       description: "2. Click on settings",
    //       imageSrc: step2,
    //       number: "2",
    //     },
    //     {
    //       description: "3. Press on Delete Account and confirm deleting",
    //       imageSrc: step3,
    //       number: "3",
    //     },
    //   ],
    // },
    // {
    //   turorial: true,
    //   title: "How To Post a Photo on 3VO",
    //   steps: [
    //     {
    //       description: "1. Open 3VO App menu",
    //       imageSrc: step1,
    //       number: "1",
    //     },
    //     {
    //       description: "2. Click on settings",
    //       imageSrc: step2,
    //       number: "2",
    //     },
    //     {
    //       description: "3. Press on Delete Account and confirm deleting",
    //       imageSrc: step3,
    //       number: "3",
    //     },
    //   ],
    // },
    // {
    //   turorial: true,
    //   title: "How To Post a Video on 3VO",
    //   steps: [
    //     {
    //       description: "1. Open 3VO App menu",
    //       imageSrc: step1,
    //       number: "1",
    //     },
    //     {
    //       description: "2. Click on settings",
    //       imageSrc: step2,
    //       number: "2",
    //     },
    //     {
    //       description: "3. Press on Delete Account and confirm deleting",
    //       imageSrc: step3,
    //       number: "3",
    //     },
    //   ],
    // },

    // {
    //   turorial: false,
    //   title: "Wallet",
    //   steps: [],
    // },
    // {
    //   turorial: true,
    //   title: "How To Create A Wallet",
    //   steps: [
    //     {
    //       description: "1. Open 3VO App menu",
    //       imageSrc: step1,
    //       number: "1",
    //     },
    //     {
    //       description: "2. Click on settings",
    //       imageSrc: step2,
    //       number: "2",
    //     },
    //     {
    //       description: "3. Press on Delete Account and confirm deleting",
    //       imageSrc: step3,
    //       number: "3",
    //     },
    //   ],
    // },
    // {
    //   turorial: true,
    //   title: "How To Top-up Your Wallet",
    //   steps: [
    //     {
    //       description: "1. Open 3VO App menu",
    //       imageSrc: step1,
    //       number: "1",
    //     },
    //     {
    //       description: "2. Click on settings",
    //       imageSrc: step2,
    //       number: "2",
    //     },
    //     {
    //       description: "3. Press on Delete Account and confirm deleting",
    //       imageSrc: step3,
    //       number: "3",
    //     },
    //   ],
    // },
    // {
    //   turorial: true,
    //   title: "How To Send Crypto",
    //   steps: [
    //     {
    //       description: "1. Open 3VO App menu",
    //       imageSrc: step1,
    //       number: "1",
    //     },
    //     {
    //       description: "2. Click on settings",
    //       imageSrc: step2,
    //       number: "2",
    //     },
    //     {
    //       description: "3. Press on Delete Account and confirm deleting",
    //       imageSrc: step3,
    //       number: "3",
    //     },
    //   ],
    // },
    // {
    //   turorial: true,
    //   title: "How To Receive Crypto",
    //   steps: [
    //     {
    //       description: "1. Open 3VO App menu",
    //       imageSrc: step1,
    //       number: "1",
    //     },
    //     {
    //       description: "2. Click on settings",
    //       imageSrc: step2,
    //       number: "2",
    //     },
    //     {
    //       description: "3. Press on Delete Account and confirm deleting",
    //       imageSrc: step3,
    //       number: "3",
    //     },
    //   ],
    // },
  ];

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <div className="embed">
        <div className={`embed-container-tut`}>
          <div
            className="embed-title"
            onClick={() => toggleItem(expandedIndex)}
          >
            <img src={logo} alt="3vo_logo"></img> Tutorials
          </div>
          <div className="tutorial-list">
            {tutorialItems.map((item, index) =>
              item.turorial === true ? (
                <div
                  key={index}
                  className={`tutorial-item ${
                    expandedIndex === index ? "expanded" : ""
                  }`}
                  onClick={() => toggleItem(index)}
                >
                  <div className="tutorial-item-title">{item.title}</div>
                  {expandedIndex === index && (
                    <div
                      className="tutorial-step"
                      onClick={handleClickInsideExpandedArea}
                    >
                      <div className="step-description">
                        <div className="step-description-container">
                          {item.steps[currentStep].description}
                        </div>
                        <div className="step-navigation">
                          <button
                            onClick={prevStep}
                            disabled={currentStep === 0}
                          >
                            Previous
                          </button>
                          <button
                            onClick={nextStep}
                            disabled={currentStep === item.steps.length - 1}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                      <div className="step-image">
                        <img
                          src={item.steps[currentStep].imageSrc}
                          alt={`Step ${currentStep + 1}`}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="tutorial-title-title">{item.title}</div>
              )
            )}
          </div>
        </div>
        <div className="embed-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Tutorial;
