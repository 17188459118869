import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mysteryCall.css";

function MysteryCall() {
  const { theme } = useTheme();

  return (
    <div className={`mysteryCall ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="freesomCall-container">
        <div className="mysteryCall-title">
          Join the 3VO Mystery Box campaign now
        </div>
        <div className="freesomCall-action">
          <a
            href="https://t.me/mysteryBox3voBot?start=claim"
            target="_blank"
            rel="noreferrer"
          >
            <div className="freesomCall-action-button">Join Now</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default MysteryCall;
