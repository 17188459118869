import React, { useEffect, useState } from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import logo from "../assets/svg/logo-inner-colored.svg";
import logo2 from "../assets/svg/logo.svg";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";

function RequestUsername() {
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  document.title = "3VO | Request Verification";

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://cdn.bitrix24.com/b26940945/crm/form/loader_22.js" +
      "?" +
      ((Date.now() / 180000) | 0);
    script.onload = () => {
      setTimeout(() => setIsLoading(false), 1500); // 1000 milliseconds delay
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const scriptDiv = {
    __html: `<script data-b24-form="inline/22/t0ju17" data-skip-moving="true"></script>`,
  };

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <div className="embed">
        <div className={`embed-container ${isLoading ? "loading" : ""}`}>
          <div className="embed-title">
            <img src={logo} alt="3vo_logo"></img> Verification
          </div>
          <div className="embed-form-description">
            <p>You can use the form below for two purposes:</p>
            <p>
              - Reserve your in-app 3VO username early (all future usernames
              will be NFTs). <br />- Apply for "verified" status to access early
              features (like tipping) and prove you are the real creator, not an
              impersonator.
            </p>
          </div>
          {isLoading ? (
            <div className="embed-loading-container">
              <img className="embed-loading" src={logo2} alt="Loading..." />
            </div>
          ) : (
            <></>
          )}
          <div className="form" dangerouslySetInnerHTML={scriptDiv} />
        </div>
        <div className="embed-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RequestUsername;
