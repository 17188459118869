import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainUsecases.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function Usecases() {
  const { theme } = useTheme();

  const useCasesData = [
    {
      title: "Creators",
      description:
        "Create more than just content. Deliver unique experiences that best suit your niche. Earn instantly with built-in monetization.",
      gradient:
        theme === "light"
          ? "linear-gradient(90deg, #6C84F8 0%, #FE05D3 100%)"
          : "linear-gradient(90deg, #FE01D2 0%, #6F82F8 100%)",
      color: theme === "light" ? "#6C84F8" : "#FE01D2",
    },
    {
      title: "Organizations",
      description:
        "Foster a community-driven movement.  Create DAO and proposals enabling democratic decision making.",
      gradient:
        theme === "light"
          ? "linear-gradient(90deg, #FE01D2 0%, #6F82F8 100%)"
          : "linear-gradient(90deg, #27C3F9 0%, #01FE8F 100%)",
      color: theme === "light" ? "#FE01D2" : "#27C3F9",
    },
    {
      title: "Developers",
      description:
        "Leverage your expertise and build with our SDKs.  Monetize your Dapps and extension directly",
      gradient:
        theme === "light"
          ? "linear-gradient(90deg, #27C3F9 0%, #01FE8F 100%)"
          : "linear-gradient(90deg, #D129DC 0%, #0CDBFD 99.92%)",
      color: theme === "light" ? "#27C3F9" : "#D129DC",
    },
  ];

  const useCasesData2 = [
    {
      title: "Businesses",
      description:
        "Bring your business to web3. Deliver content, engage customers with interactive Dapps. Accept crypto payments and create loyalty tokens",
      gradient:
        theme === "light"
          ? "linear-gradient(90deg, #01E5FF 0%, #03FE8F 100%)"
          : "linear-gradient(90deg, #00FE8D 0%, #01E6FD 100%)",
      color: theme === "light" ? "#01E5FF" : "#00FE8D",
    },
    {
      title: "Communities",
      description:
        "Build decentralized communities.  Issue tokens, enable votes , build transparent dashboards, or deploy interactive Dapps.",
      gradient:
        theme === "light"
          ? "linear-gradient(90deg, #00FE8D 0%, #01E6FD 100%)"
          : "linear-gradient(90deg, #D129DC 0%, #0CDBFD 99.92%)",
      color: theme === "light" ? "#00FE8D" : "#D129DC",
    },
    {
      title: "Entrepreneurs",
      description:
        "Deliver unique experiences that best suit your niche. Earn instantly with built-in monetization​",
      gradient:
        theme === "light"
          ? "linear-gradient(90deg, #D129DC 0%, #0CDBFD 99.92%)"
          : "linear-gradient(90deg, #6C84F8 0%, #FE05D3 100%)",
      color: theme === "light" ? "#D129DC" : "#6C84F8",
    },
  ];

  return (
    <div className={`usecases ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="usecases-container">
        <div className="usecases-text">
          <div className="usecases-text-slide">
            {useCasesData2.map((useCase, index) => (
              <div key={index} className="usecases-text-usecase">
                <div
                  className="usecases-text-usecase-title"
                  style={{
                    backgroundImage: useCase.gradient,
                    color: useCase.color,
                  }}
                >
                  {useCase.title}
                </div>
                <div className="usecases-text-usecase-description">
                  {useCase.description}
                </div>
              </div>
            ))}
          </div>
          <div className="usecases-text-slide2">
            {useCasesData.map((useCase, index) => (
              <div key={index} className="usecases-text-usecase">
                <div
                  className="usecases-text-usecase-title"
                  style={{
                    backgroundImage: useCase.gradient,
                    color: useCase.color,
                  }}
                >
                  {useCase.title}
                </div>
                <div className="usecases-text-usecase-description">
                  {useCase.description}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="usecases-madefor">Made For</div>
        <div className="usecases-everyone">Everyone</div>
      </div>
      <div className="usecases-learnmore">
        <div className="usecases-button">
          <a href="/usecases" target="" rel="noreferrer">
            <div className="usecases-action-button">Learn More</div>
          </a>
        </div>
      </div>
      <div className="usecases-background">
        <img src={logo} alt="3vo-superapp"></img> 
      </div>
    </div>
  );
}

export default Usecases;
