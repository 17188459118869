import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./defiHero.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function DefiHero() {
  const { theme } = useTheme();

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className={`defiHero ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="defiHero-container">
        <div className="defiHero-title">
          <div>
            <div className="defiHero-title-alpha">( Public Alpha )</div>
            <div className="defiHero-title-title">
              <span className="specific-text">3VO</span> DeFi Wallet
            </div>
          </div>
          <div className="defiHero-title-subtitle">
            Your simple gateway to the world of Decentralized Finance
          </div>
        </div>
        <div className="defiHero-button-container">
          <div className="defiHero-button" onClick={scrollToBottom}>
            <div className="defiHero-button-title">Try 3VO DeFi Alpha</div>
          </div>
        </div>
      </div>
      <div className="defiHero-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default DefiHero;
