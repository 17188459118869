import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./freedomPhotos.css";

import community from "../../assets/images/community.webp";
import web3 from "../../assets/images/web3.webp";
import offer from "../../assets/images/offers.webp";

function FreedomPhotos() {
  const { theme } = useTheme();

  return (
    <div className={`freedomPhotos ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="freedomPhotos-container">
        <div className="freedomPhotos-image-phone">
          <img src={community} alt="3DOM Community" />
        </div>
        <div className="freedomPhotos-image-tablet">
          <img src={offer} alt="3DOM Offers" />
        </div>
        <div className="freedomPhotos-image-middle">
          <img src={web3} alt="3DOM Web3 Discover" />
        </div>
      </div>
    </div>
  );
}

export default FreedomPhotos;
