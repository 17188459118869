import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./howToJoin.css";
import joinTelegram from "../../assets/svg/JoinTelegram.svg";
import openBox from "../../assets/svg/openBox.svg";
import getGifts from "../../assets/svg/getGift.svg";

function HowToJoin() {
  const { theme } = useTheme();

  return (
    <div className={`mysteryBoxMain ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="mysteryBox-how-to-container">
        <div className="mysteryBoxMain-title">
          <div>
            <div className="mysteryBoxMain-title-tit">
                Join the Fun in Just 3 Simple Steps!
            </div>
          </div>
        </div>{" "}
        <div className="mysteryBox-how-to">
          <div className="mysteryBox-how-to-item">
            <div className="mysteryBox-how-to-item-image">
              <img src={joinTelegram} alt="Join Telegram"></img>
            </div>
            <div className="mysteryBox-how-to-item-text">
              <div className="mysteryBox-how-to-item-text-title">
                Join Telegram Groups
              </div>
              <div className="mysteryBox-how-to-item-text-desc">
                Join the Telegram groups of participating projects, and be part
                of the campaign by simply interact with people.
              </div>
            </div>
          </div>
          <div className="mysteryBox-how-to-item">
            <div className="mysteryBox-how-to-item-image">
              <img src={openBox} alt="Join Telegram"></img>
            </div>
            <div className="mysteryBox-how-to-item-text">
              <div className="mysteryBox-how-to-item-text-title">
                Open Boxes
              </div>
              <div className="mysteryBox-how-to-item-text-desc">
                Watch for Mystery Boxes that drop when certain criteria are met
                and claim them on the chat once they do!
              </div>
            </div>
          </div>
          <div className="mysteryBox-how-to-item">
            <div className="mysteryBox-how-to-item-image">
              <img src={getGifts} alt="Join Telegram"></img>
            </div>
            <div className="mysteryBox-how-to-item-text">
              <div className="mysteryBox-how-to-item-text-title">
                Uncover Rewards
              </div>
              <div className="mysteryBox-how-to-item-text-desc">
                Uncover awesome instant rewards and start collecting cards for a
                chance to unlock each partner's Loot Chest for biggest
                surprises!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowToJoin;
