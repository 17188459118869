import React, { useState, useEffect } from "react";
import { useTheme } from "../../ThemeProvider";
import "./socialCards.css";

import content from "../../assets/images/createcontent.webp";
import socialTip from "../../assets/images/socialtipping.webp";
import conversation from "../../assets/images/converstation.webp";
import sendtoken from "../../assets/images/sendtoken.webp";

function SocialCards() {
  const { theme } = useTheme();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 550) {
        setIsMobile(true); // Corrected to set isMobile to true
      } else {
        setIsMobile(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`socialCards ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="socialCards-container">
        {!isMobile ? (
          <>
            <div className="socialCards-feature">
              <div className="socialCards-text">
                <div className="socialCards-text-title">Create Content</div>
                <div className="socialCards-text-description">
                  Instantly create content posts, pictures, videos, sound,
                  stories, live etc.
                </div>
              </div>
              <div className="socialCards-image-container-third-border">
                <div className="socialCards-image-container-third">
                  <img src={content} alt="3VO Tokens" />
                </div>
              </div>
            </div>
            <div className="socialCards-feature">
              <div className="socialCards-image-container-fourth-border">
                <div className="socialCards-image-container-fourth">
                  <img src={conversation} alt="3VO Tokens" />
                </div>
              </div>
              <div className="socialCards-text-r">
                <div className="socialCards-text-title">Communicate</div>
                <div className="socialCards-text-description">
                  Talk with friends via text, voice, or video. Send / receive
                  money or share and invite to activities directly from the
                  chat.
                </div>
              </div>
            </div>
            <div className="socialCards-feature">
              <div className="socialCards-text">
                <div className="socialCards-text-title">Monetize</div>
                <div className="socialCards-text-description">
                  Earn directly from your followers with social tipping. Own
                  your content by tokenizing it. Allow your most loyal fans to
                  support you by owning your creation or even create your own
                  token in seconds.
                </div>
              </div>
              <div className="socialCards-image-container-third-border">
                <div className="socialCards-image-container-third">
                  <img src={socialTip} alt="3VO Tokens" />
                </div>
              </div>
            </div>
            <div className="socialCards-feature">
              <div className="socialCards-image-container-fourth-border">
                <div className="socialCards-image-container-fourth">
                  <img src={sendtoken} alt="3VO Tokens" />
                </div>
              </div>
              <div className="socialCards-text-r">
                <div className="socialCards-text-title">
                  Transact As Easy As Sending A Message
                </div>
                <div className="socialCards-text-description">
                  Enjoy seamless instant transactions powered by web3 with
                  built-in multichain wallet for all your digital value.
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="socialCards-feature">
              <div className="socialCards-image-container-fourth-border">
                <div className="socialCards-image-container-fourth">
                  <img src={content} alt="3VO Tokens" />
                </div>
              </div>
              <div className="socialCards-text">
                <div className="socialCards-text-title">Create Content</div>
                <div className="socialCards-text-description">
                  Instantly create content posts, pictures, videos, sound,
                  stories, live etc.
                </div>
              </div>
            </div>
            <div className="socialCards-feature">
              <div className="socialCards-image-container-fourth-border">
                <div className="socialCards-image-container-fourth">
                  <img src={conversation} alt="3VO Tokens" />
                </div>
              </div>
              <div className="socialCards-text-r">
                <div className="socialCards-text-title">Communicate</div>
                <div className="socialCards-text-description">
                  Talk with friends via text, voice, or video. Send / receive
                  money or share and invite to activities directly from the
                  chat.
                </div>
              </div>
            </div>
            <div className="socialCards-feature">
              <div className="socialCards-image-container-fourth-border">
                <div className="socialCards-image-container-fourth">
                  <img src={socialTip} alt="3VO Tokens" />
                </div>
              </div>
              <div className="socialCards-text">
                <div className="socialCards-text-title">Monetize</div>
                <div className="socialCards-text-description">
                  Earn directly from your followers with social tipping. Own
                  your content by tokenizing it. Allow your most loyal fans to
                  support you by owning your creation or even create your own
                  token in seconds.
                </div>
              </div>
            </div>
            <div className="socialCards-feature">
              <div className="socialCards-image-container-fourth-border">
                <div className="socialCards-image-container-fourth">
                  <img src={sendtoken} alt="3VO Tokens" />
                </div>
              </div>
              <div className="socialCards-text-r">
                <div className="socialCards-text-title">
                  Transact As Easy As Sending A Message
                </div>
                <div className="socialCards-text-description">
                  Enjoy seamless instant transactions powered by web3 with
                  built-in multichain wallet for all your digital value.
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SocialCards;
