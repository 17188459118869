import React from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import SingleUsecaseCall from "../singleUsecase/singleUsecaseCall/singleUsecaseCall.js";
import SingleUsecaseHero from "../singleUsecase/singleUsecaseHero/singleUsecaseHero.js";
import SingleUsecaseDescription from "../singleUsecase/singleUsecaseDescription/singleUsecaseDescription.js";
import SingleUsecasePhoto from "../singleUsecase/singleUsecasePhoto/singleUsecasePhoto.js";

import a from "../assets/player/01.webp";
import b from "../assets/player/02.webp";
import c from "../assets/player/03.webp";
import d from "../assets/player/04.webp";
import e from "../assets/player/05.webp";

function PlayerOne() {
  const { theme } = useTheme();

  document.title = "3VO | PlayerOne";

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <SingleUsecaseHero title="PlayerOne" subtitle="" />
      <SingleUsecasePhoto img={a} name="PlayerOne" />
      <SingleUsecasePhoto img={b} name="PlayerOne" />
      <SingleUsecasePhoto img={c} name="PlayerOne" />
      <SingleUsecasePhoto img={d} name="PlayerOne" />
      <SingleUsecasePhoto img={e} name="PlayerOne" />
      <SingleUsecaseCall />
      <Footer />
    </div>
  );
}

export default PlayerOne;
