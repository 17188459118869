import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainHero.css";
import image from "../../assets/images/main-bg.webp";
import { Carousel } from "react-responsive-carousel";

const words = ["Monetize", "Tokenize", "Transact", "Discover", "Create"];

function Hero() {
  const { theme } = useTheme();

  return (
    <div className={`hero ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="hero-container">
        <div className="hero-main">
          <div className="hero-main-main">
            <div className="hero-main-main-title">
              <div className="hero-main-main-text">Web3</div>
              <div className="hero-main-main-text-color">SuperApp</div>
            </div>
            <div className="hero-main-main-title-second">
              <div className="hero-main-main-text">For Digital</div>
              <div className="hero-main-main-text-color">Value</div>
            </div>
          </div>
          <div className="hero-main-secondary">
            <div className="hero-main-secondary-text">
              All-in-one Ecosystem to
            </div>
            <div className="hero-main-secondary-moving">
              <Carousel
                autoPlay={true}
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                axis="vertical"
              >
                {words.map((word, index) => (
                  <span key={index}>
                    <b>{word}</b>
                  </span>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="hero-button">
            <a href="/presale" target="" rel="noreferrer">
              <div className="hero-action-button">Join Pre-sale</div>
            </a>
          </div>
        </div>
        <div className="hero-background">
          <img src={image} alt="3vo-superapp"></img>
        </div>
      </div>
    </div>
  );
}

export default Hero;
