import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const apiKey = process.env.REACT_APP_API_KEY_SECOND;

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "vo-web.firebaseapp.com",
  projectId: "vo-web",
  storageBucket: "vo-web.appspot.com",
  messagingSenderId: "70974855222",
  appId: "1:70974855222:web:ffc7e9faaa61f7f839e097",
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const firestore = getFirestore(app);

export { firestore };
