import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./usecasesMore.css";

import logo from "../../assets/svg/logo-inner-colored.svg";
import venture from "../../assets/svg/venture.svg";
import org from "../../assets/svg/organization.svg";
import streamer from "../../assets/svg/streamer.svg";
import community from "../../assets/svg/community.svg";
import trader from "../../assets/svg/trader.svg";
import athlete from "../../assets/svg/athlete.svg";
import teacher from "../../assets/svg/education.svg";
import artist from "../../assets/svg/artist.svg";

function UsecasesMore() {
  const { theme } = useTheme();

  const features = [
    {
      title: "Venture Capital",
      icon: venture,
    },
    {
      title: "Organization",
      icon: org,
    },
    {
      title: "Streamer",
      icon: streamer,
    },
    {
      title: "Community",
      icon: community,
    },
    {
      title: "Trader",
      icon: trader,
    },
    {
      title: "Athlete",
      icon: athlete,
    },
    {
      title: "Teacher",
      icon: teacher,
    },
    {
      title: "Artist",
      icon: artist,
    },
  ];

  return (
    <div className={`usecasesMore ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="usecasesMore-container">
        <div className="usecasesMore-features">
          {features.map((feature, index) => (
            <div key={index} className="usecasesMore-feature">
              <div className="usecasesMore-feature-icon">
                <img src={feature.icon} alt="Feature Icon" />
              </div>
              <div className="usecasesMore-feature-text">
                <div className="usecasesMore-feature-title">
                  {feature.title}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="usecasesMore-title">And Many More</div>
      </div>
      <div className="usecasesMore-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default UsecasesMore;
