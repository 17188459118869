import React from "react";

function QuestsRules() {
  return (
    <div className="quests-rules">
      <b className="quests-rules-title">How to Enter</b>
      <br />
      <br />- <strong className="quest-speical-word">Followers</strong> : Get
      followers on 3VO app.
      <br />- <strong className="quest-speical-word">Transactions</strong> :
      Make transactions ( Tip or Transfer ) on 3VO app.
      <br />- <strong className="quest-speical-word">Posts</strong> : post daily
      on 3VO app.
      <br />- <strong className="quest-speical-word">Likes</strong> : Get likes
      on your posts on 3VO app
      <br />
      <br />
      <b className="quests-rules-title">Points Structure</b>
      <br />
      <br />- <strong className="quest-speical-word1">1</strong> Follow ={" "}
      <strong className="quest-speical-word1">4 </strong>
      Points
      <br />- <strong className="quest-speical-word1">$1</strong> Transaction ={" "}
      <strong className="quest-speical-word1">1 </strong>
      Point ( Limited to 20 points per day)
      <br />- <strong className="quest-speical-word1">1</strong> Post ={" "}
      <strong className="quest-speical-word1">10 </strong>
      points ( Limited to 3 posts per day)
      <br />- <strong className="quest-speical-word1">1</strong> Like ={" "}
      <strong className="quest-speical-word1">1</strong> points ( Limited to
      likes on the 3 posts per day)
      <br />- <strong className="quest-speical-word1">Special Points</strong> are rewarded for special events, keep an eye out on 3VO Telegram Group
      <br />
      <br />
      <b className="quests-rules-title">Terms and Conditions</b>
      {/* <br />
      <br />
      <strong>Rewards:</strong> */}
      <br />
      <br />- All rewards and points showing in this page before the{" "}
      <strong className="quest-speical-word1">
        End of the event are not fixed
      </strong>
      , rewards will be caluclated as soon as the season ends.
      <br />- <strong className="quest-speical-word1">USDT</strong> rewards will
      be distributed within <strong>1 MONTH</strong> after the season has
      finished
      <br />- <strong className="quest-speical-word1">$3VO</strong> rewards will
      be distributed within{" "}
      <strong className="quest-speical-word1">2 MONTH</strong> after the{" "}
      <strong className="quest-speical-word1">TGE</strong> ( Token Generation
      Event )
      <br />- All the rewards will be distributed to the{" "}
      <strong className="quest-speical-word1">
        Wallet Connected to 3VO Account
      </strong>
    </div>
  );
}

export default QuestsRules;
