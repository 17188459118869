import React, { useEffect, useState } from "react";
import { useTheme } from "../../ThemeProvider";
import "./mysteryCall2.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function MysteryCall2() {
  const { theme } = useTheme();

  const [isMobile, setIsMobile] = useState(128);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 850) {
        setIsMobile(80);
      } else {
        setIsMobile(128);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`mysteryCall2 ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="mysteryCall2-container">
        <div className="mysteryCall2-title">
          Find Up To
          <div className="mysteryBox-title-title">
            <span className="specific-text" style={{ fontSize: isMobile }}>
              $100,000
            </span>
          </div>{" "}
          <div className="mysteryBox-title-subtitle">
            Instant prizes from Mystery Boxes - Loot Chest rewards - Race prizes
            - Exclusive NFTs and more!
          </div>
        </div>

        <div className="mysteryCall2-button">
          <a
            href="https://t.me/mysteryBox3voBot"
            target="_blank"
            rel="noreferrer"
          >
            <div className="callToAction-action-button">
              Join The Campaign Now !
            </div>
          </a>
        </div>
        <div className="mysteryCall2-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
    </div>
  );
}

export default MysteryCall2;
