import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainDecentralized.css";

function Decentralized() {
  const { theme } = useTheme();

  return (
    <div className={`decentralized ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="decentralized-container">
        <div className="decentralized-title">
          Powerful social platform for the new decentralized economy​
        </div>
      </div>
    </div>
  );
}

export default Decentralized;
