import React from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import DappsHero from "../dapps/dappsHero/dappsHero.js";
import DappsStatement from "../dapps/dappsStatement/dappsStatement.js";
import DappsCards from "../dapps/dappsCards/dappsCards.js";
import DappsCall from "../dapps/dappsCall/dappsCall.js";

function Dapps() {
  const { theme } = useTheme();

  document.title = "3VO | Dapps";

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <DappsHero />
      <DappsStatement />
      <DappsCards />
      <DappsCall />
      <Footer />
    </div>
  );
}

export default Dapps;
