import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./freedomCards.css";

import discover from "../../assets/images/discover.webp";
import token from "../../assets/images/tokenmaya.webp";
import profile from "../../assets/images/profilehao.webp";

function FreedomCards() {
  const { theme } = useTheme();

  return (
    <div className={`freedomCards ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="freedomCards-container">
        <div className="freedomCards-card-container">
          <div className="freedomCards-card">
            <div className="freedomCards-card-background"></div>
            <div className="freedomCards-card-text">
              <div className="freedomCards-card-text-title">
                Build No-Code DApps{" "}
              </div>
              <div className="freedomCards-card-text-description">
                Bring your brand into the era of web3. Turn profile pages into
                interactive decentralized applications with a library of
                ready-to-deploy templates that suit your audience.
              </div>
            </div>
            <div className="freedomCards-card-picture">
              <img src={profile} alt="3VO Discover"></img>
            </div>
          </div>
        </div>
        <div className="freedomCards-card-container">
          <div className="freedomCards-card">
            <div className="freedomCards-card-background"></div>
            <div className="freedomCards-card-picture">
              <img src={token} alt="3VO Discover"></img>
            </div>
            <div className="freedomCards-card-text">
              <div className="freedomCards-card-text-title">
                Create Tokens Effortlessly
              </div>
              <div className="freedomCards-card-text-description">
                Transform your value into a token with a user-friendly tool to
                create any token with no coding required and powered by a
                library of audited smart contracts.
              </div>
            </div>
          </div>
        </div>
        <div className="freedomCards-card-container">
          <div className="freedomCards-card">
            <div className="freedomCards-card-background"></div>
            <div className="freedomCards-card-text">
              <div className="freedomCards-card-text-title">
                Discover The World of 3VO Social
              </div>
              <div className="freedomCards-card-text-description">
                Discover what is trending around you. Whether it's news, media,
                offers and events or financial opportunities in the world of
                web3, never miss out and be at the center of action.
              </div>
            </div>
            <div className="freedomCards-card-picture">
              <img src={discover} alt="3VO Discover"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreedomCards;
