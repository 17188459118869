import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainCallToAction.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function CallToAction() {
  const { theme } = useTheme();

  return (
    <div className={`callToAction ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="callToAction-container">
        <div className="callToAction-title">
          Join the thousands of visionaries and creators building 3VO.
        </div>
        <div className="callToAction-button">
          <a href="/presale" target="" rel="noreferrer">
            <div className="callToAction-action-button">Join $3VO Pre-Sale</div>
          </a>
        </div>
        <div className="callToAction-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
