import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./socialFeatures.css";

import plus from "../../assets/svg/plus.svg";
import logo from "../../assets/svg/logo-inner-colored.svg";

function SocialFeatures() {
  const { theme } = useTheme();

  const features = [
    {
      title: "EVM Compatible",
      description: "",
      icon: plus,
    },
    {
      title: "Secure",
      description: "",
      icon: plus,
    },
    {
      title: "NFT Username",
      description: "",
      icon: plus,
    },
    {
      title: "Built-in Wallet",
      description: "",
      icon: plus,
    },
    {
      title: "End-to-End Encryption Message",
      description: "",
      icon: plus,
    },
    {
      title: "Fiat Off-Ramp / On-Ramp",
      description: "",
      icon: plus,
    },
    {
      title: "Built-in Bridges",
      description: "",
      icon: plus,
    },
    {
      title: "Virtual Cards",
      description: "",
      icon: plus,
    },
    {
      title: "Token Exchange",
      description: "",
      icon: plus,
    },
    {
      title: "Hybrid Wallet",
      description: "",
      icon: plus,
    },
    {
      title: "Identity Verification",
      description: "",
      icon: plus,
    },
    {
      title: "Seamless Transactions ",
      description: "",
      icon: plus,
    },
  ];

  return (
    <div className={`socialFeatures ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="socialFeatures-container">
        <div className="socialFeatures-title">Features</div>
        <div className="socialFeatures-features">
          {features.map((feature, index) => (
            <div key={index} className="socialFeatures-feature">
              <div className="socialFeatures-feature-icon">
                <img src={feature.icon} alt="Feature Icon" />
              </div>
              <div className="socialFeatures-feature-text">
                <div className="socialFeatures-feature-title">
                  {feature.title}
                </div>
                <div className="socialFeatures-feature-description">
                  {feature.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="socialFeatures-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default SocialFeatures;
