import React from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import FreedomHero from "../freedom/freedomHero/freedomHero.js";
import FreedomPhotos from "../freedom/freedomPhotos/freedomPhotos.js";
import FreedomDescription from "../freedom/freedomDescription/freedomDescription.js";
import FreedomCards from "../freedom/freedomCards/freedomCards.js";
import FreedomFeatures from "../freedom/freedomFeatures/freedomFeatures.js";
import FreedomCall from "../freedom/freedomCall/freedomCall.js";

function Freedom() {
  const { theme } = useTheme();

  document.title = "3VO | Hub";

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <FreedomHero />
      <FreedomPhotos />
      <FreedomDescription />
      <FreedomCards />
      <FreedomFeatures />
      <FreedomCall />
      <Footer />
    </div>
  );
}

export default Freedom;
