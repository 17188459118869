import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./defiPhotos.css";

import defi1 from "../../assets/images/defi1.webp";
import defi2 from "../../assets/images/defi2.webp";
import defi3 from "../../assets/images/defi3.webp";

function DefiPhotos() {
  const { theme } = useTheme();

  return (
    <div className={`defiPhotos ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="defiPhotos-container">
        <div className="defiPhotos-image-middle">
          <img src={defi2} alt="3VO Defi" />
        </div>
        <div className="defiPhotos-image">
          <img src={defi1} alt="3VO Defi" />
        </div>
        <div className="defiPhotos-image">
          <img src={defi3} alt="3VO Defi" />
        </div>
      </div>
    </div>
  );
}

export default DefiPhotos;
