import React, { useState, useEffect } from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainToken.css";
import tokenImage from "../../assets/images/coins.webp";

import tokenomics from "../../assets/files/3vo-tokenomics-V3.0.pdf";

function Token() {
  const { theme } = useTheme();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 400);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`token ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="token-token">
        <div className="token-token-details">
          <div className="token-token-details-metrics">
            <div className="token-token-details-metrics-title">Metrics</div>
            {/*  */}
            <div className="token-token-details-metrics-row">
              <div className="token-token-details-metrics-item">
                <div className="token-token-details-metrics-item-title">
                  Blockchain
                </div>
                <div className="token-token-details-metrics-item-value">
                  ETH, Polygon, BNB
                </div>
              </div>
              <div className="token-token-details-metrics-item">
                <div className="token-token-details-metrics-item-title">
                  Project Valuation
                </div>
                <div className="token-token-details-metrics-item-value">
                  $20,000,000
                </div>
              </div>
            </div>
            <div className="token-token-details-metrics-row">
              <div className="token-token-details-metrics-item">
                <div className="token-token-details-metrics-item-title">
                  Initial Marketcap
                </div>
                <div className="token-token-details-metrics-item-value">
                  $651,000
                </div>
              </div>
              <div className="token-token-details-metrics-item">
                <div className="token-token-details-metrics-item-title">
                  Listing Price
                </div>
                <div className="token-token-details-metrics-item-value">
                  $0.01
                </div>
              </div>
            </div>
            {/*  */}
          </div>
          <div className="token-token-details-utilities">
            <div className="token-token-details-utilities-title">Utilities</div>
            <div className="token-token-details-utilities-utilities">
              {!isSmallScreen ? (
                <>
                  <div className="token-token-details-utilities-utilities-row">
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        Transaction Fee
                      </div>
                    </div>
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        Premium Tools
                      </div>
                    </div>
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        Bridge Token
                      </div>
                    </div>
                  </div>
                  <div className="token-token-details-utilities-utilities-row">
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        Protocol Management
                      </div>
                    </div>
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        Creator Token Liquidity
                      </div>
                    </div>
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        User Incentives
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="token-token-details-utilities-utilities-row">
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        Transaction Fee
                      </div>
                    </div>
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        Premium Tools
                      </div>
                    </div>
                  </div>
                  <div className="token-token-details-utilities-utilities-row">
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        Bridge Token
                      </div>
                    </div>
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        Protocol Management
                      </div>
                    </div>
                  </div>
                  <div className="token-token-details-utilities-utilities-row">
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        Creator Token Liquidity
                      </div>
                    </div>
                    <div className="token-token-details-utilities-utilities-utility">
                      <div className="token-token-details-utilities-utilities-utility-text">
                        User Incentives
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="token-token-filler">
          <img src={tokenImage} alt="3VO Token" />
        </div>
      </div>
      <div className="token-buttons">
        <div className="token-buttons-action">
          <a
            href="/token#tokenomics"
            target=""
            rel="noreferrer"
            className="token-buttons-button"
          >
            Tokenomics
          </a>
          <a
            href="/presale"
            target="_blank"
            rel="noreferrer"
            className="token-buttons-button"
          >
            Get $3VO
          </a>
        </div>
        <div className="token-buttons-filler"></div>
      </div>
    </div>
  );
}

export default Token;
