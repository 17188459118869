import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./dappsStatement.css";

function DappsStatement() {
  const { theme } = useTheme();

  return (
    <div className={`dappsStatement ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="dappsStatement-container">
        <div className="dappsStatement-title">
          Transform Social Pages Into Dynamic, Customizable Dapps
        </div>
      </div>
    </div>
  );
}

export default DappsStatement;
