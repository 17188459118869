import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./tokenCall.css";

function TokenCall() {
  const { theme } = useTheme();

  return (
    <div className={`tokenCall ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="tokenCall-container">
        <div className="tokenCall-title">
          Join the thousands of visionaries and creators building 3VO.
        </div>
        <div className="tokenCall-action">
          <a href="/presale" target="" rel="noreferrer">
            <div className="tokenCall-action-button">Join Presale</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default TokenCall;
