import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./freedomDescription.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function FreedomDescription() {
  const { theme } = useTheme();

  return (
    <div
      className={`freedomDescription ${theme === "light" ? "" : "dark-theme"}`}
    >
      <div className="freedomDescription-container">
        <div className="freedomDescription-title">
          A World of Value Powered by Web3
        </div>
        <div className="freedomDescription-para">
          Envision one single hub as a solution for Creating, Delivering and
          Transacting all your digital value.
          <br />
          <br />
          By seamlessly integrating Social Media and Digital Assets, 3vo
          provides a simple, unified solution for your online presence. enabling
          users to perform value-based interactions.
        </div>
        <div className="freedomDescription-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
    </div>
  );
}

export default FreedomDescription;
