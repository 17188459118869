import React from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import logo from "../assets/svg/logo-inner-colored.svg";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";

function PrivacyPolicy() {
  const { theme } = useTheme();

  document.title = "3VO | Privacy Policy";

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <div className="embed">
        <div className="embed-container">
          <div className="embed-title">3VO Privacy Policy</div>
          <div className="embed-date">Last modified date: 20/11/2023</div>
          <div className="embed-privacy">
            <p>
              This privacy policy (“Policy”) describes how 3VO S.A. (“Company”,
              “we”, “our”, or “us”) collects, uses, shares, and stores personal
              information of users of its websites, https://3vo.me/ (the
              “Sites”). This Policy applies to the Sites, applications, products
              and services (collectively, “Services”) on or in which it is
              posted, linked, or referenced. By using the Services, you accept
              the terms of this Policy and our Terms of Use, and consent to our
              collection, use, disclosure, and retention of your information as
              described in this Policy. If you have not done so already, please
              also review our terms of use. The terms of use contain provisions
              that limit our liability to you and require you to resolve any
              dispute with us on an individual basis and not as part of any
              class or representative action. IF YOU DO NOT AGREE WITH ANY PART
              OF THIS PRIVACY POLICY OR OUR TERMS OF USE, THEN PLEASE DO NOT USE
              ANY OF THE SERVICES.
            </p>
            <h1 className="embed-privacy-inner-titles">WHAT WE COLLECT</h1>
            <p>
              We get information about you in a range of ways. Information You
              Give Us. Information we collect from you may include: Identity
              information, such as your first name, last name, username or
              similar identifier, title, date of birth and gender; Contact
              information, such as your postal address, email address and
              telephone number; Profile information, such as your username and
              password, interests, preferences, feedback and survey responses;
              Feedback and correspondence, such as information you provide in
              your responses to surveys, when you participate in market research
              activities, report a problem with Service, receive customer
              support or otherwise correspond with us; Financial information,
              such as your credit card or other payment card details;
              Transaction information, such details about purchases you make
              through the Service and billing details; Usage information, such
              as information about how you use the Service and interact with us;
              Marketing information, such as your preferences for receiving
              marketing communications and details about how you engage with
              them; Financial information, such as bank account number and bank
              routing number; financial assets holdings; and Technical
              information, such as your Ethereum wallet address, application
              programming interface (API)-key and network information regarding
              transactions. <br /> <br />
              Information We Get From Others. We may get information about you
              from other third party sources and we may add this to information
              we get from your use of the Services. Such information may
              include: <br /> Registration using Single Sign-On Account: When
              registering some user accounts, you also have the option of using
              a single sign-on account (“SSO”). With an SSO, you can sign up for
              various different services and platforms with a single account.
              Some of our Sites currently offer you the opportunity to use the
              SSO services offered by Google, LLC, Amphitheatre Parkway,
              Mountain View, CA 94043, USA (“Google”). Google’s Privacy Policy
              and Terms of Use apply to the registration and use of the Google
              SSO service, see https://policies.google.com/privacy/. Please note
              that the registration for and the use of SSO services are subject
              to the Google privacy policy and terms of use, which are beyond
              our control. <br />
              <br /> Information Automatically Collected. We may automatically
              record certain information about how you use our Sites (we refer
              to this information as “Log Data“). Log Data may include
              information such as a user’s Internet Protocol (IP) address,
              device and browser type, operating system, the pages or features
              of our Sites to which a user browsed and the time spent on those
              pages or features, the frequency with which the Sites are used by
              a user, search terms, the links on our Sites that a user clicked
              on or used, and other statistics. We use this information to
              administer the Service and we analyze (and may engage third
              parties to analyze) this information to improve and enhance the
              Service by expanding its features and functionality and tailoring
              it to our users’ needs and preferences. We may use cookies, local
              storage or similar technologies to analyze trends, administer the
              Sites, track users’ movements around the Sites, and to gather
              demographic information about our user base as a whole. Users can
              control the use of cookies and local storage at the individual
              browser level. For more information, please see our Cookies
              Policy. We also may use Google Analytics to help us offer you an
              optimized user experience. You can find more information about
              Google Analytics’ use of your personal data here:
              https://www.google.com/analytics/terms/us.html.
            </p>
            <h1 className="embed-privacy-inner-titles">
              Tracking Technologies Generally
            </h1>
            <p>
              Regular cookies may generally be disabled or removed by tools
              available as part of most commercial browsers, and in some
              instances blocked in the future by selecting certain settings.
            </p>
            <h1 className="embed-privacy-inner-titles">Google Analytics</h1>
            <p>
              You may exercise choices regarding the use of cookies from Google
              Analytics by going to https://tools.google.com/dlpage/gaoptout/
              and downloading the Google Analytics Opt-out Browser Add-on.
            </p>
            <h1 className="embed-privacy-inner-titles">COOKIES</h1>
            <p>
              You can set your browser to refuse all or some browser cookies, or
              to alert you when websites set or access cookies. If you disable
              or refuse cookies, please note that some parts of the Sites may
              become inaccessible or not function properly.
            </p>
            <h1 className="embed-privacy-inner-titles">
              Information we will never collect
            </h1>
            <p>
              We will never ask you to share your private keys or wallet seed.
              Never trust anyone or any site that asks you to enter your private
              keys or wallet seed
            </p>
            <h1 className="embed-privacy-inner-titles">
              USE OF PERSONAL INFORMATION
            </h1>
            <p>
              <strong>
                To provide our service We will use your personal information in
                the following ways:
              </strong>
              <br />
              <br />
              - To enable you to access and use the Services <br />
              - To provide and deliver products and services that you may
              request. <br />
              - To process and complete transactions, and send you related
              information, including purchase confirmations and invoices <br />-
              To send information, including confirmations, technical notices,
              updates, security alerts, and support and administrative messages.
              <br />
              <br />
              <strong>To comply with law </strong>
              <br />
              <br />
              We use your personal information as we believe necessary or
              appropriate to comply with applicable laws (including anti-money
              laundering (AML) laws and know-your-customer (KYC) requirements),
              lawful requests and legal process, such as to respond to subpoenas
              or requests from government authorities. <br />
              <br />
              <strong>To communicate with you</strong> <br />
              <br />
              We use your personal information to communicate about promotions,
              upcoming events, and other news about products and services
              offered by us and our selected partners. <br />
              <br />
              <strong>To optimize our platform</strong> <br />
              <br />
              In order to optimize your user experience, we may use your
              personal information to operate, maintain, and improve our
              Services. We may also use your information to respond to your
              comments and questions regarding the Services, and to provide you
              and other users with general customer service. <br />
              <br />
              <strong>With your consent</strong> <br />
              <br />
              We may use or share your personal information with your consent,
              such as when you consent to let us post your testimonials or
              endorsements on our Sites, you instruct us to take a specific
              action with respect to your personal information, or you opt into
              third party marketing communications. <br />
              For compliance, fraud prevention, and safety <br />
              We may use your personal information to protect, investigate, and
              deter against fraudulent, unauthorized, or illegal activity.
            </p>
            <h1 className="embed-privacy-inner-titles">
              SHARING OF PERSONAL INFORMATION
            </h1>
            <p>
              We do not share the personal information that you provide us with
              other organizations without your express consent, except as
              described in this Privacy Policy. We disclose personal information
              to third parties under the following circumstances:
              <br />
              <br />
              <strong>Affiliates.</strong> We may disclose your personal
              information to our subsidiaries and corporate affiliates (i.e. our
              family of companies that are related by common ownership or
              control) for purposes consistent with this Privacy Policy.
              <br />
              <br />
              <strong>Business Transfers.</strong> We may share personal
              information when we do a business deal, or negotiate a business
              deal, involving the sale or transfer of all or a part of our
              business or assets. These deals can include any merger, financing,
              acquisition, or bankruptcy transaction or proceeding.
              <br />
              <br />
              <strong>
                Compliance with Laws and Law Enforcement; Protection and Safety.
              </strong>
              We may share personal information for legal, protection, and
              safety purposes.
              <br />
              - We may share information to comply with laws, including KYC and
              AML requirements.
              <br />
              - We may share information to respond to lawful requests and legal
              processes.
              <br />
              - We may share information to protect the rights and property of
              the Company, our agents, customers, and others. This includes
              enforcing our agreements, policies, and terms of use.
              <br />
              - We may share information in an emergency. This includes
              protecting the safety of our employees and agents, our customers,
              or any person.
              <br />
              <br />
              <strong>Professional Advisors and Service Providers.</strong> We
              may share information with those who need it to do work for us.
              These recipients may include third party companies and individuals
              to administer and provide the Service on our behalf (such as bill
              and credit card payment processing, customer support, hosting,
              email delivery and database management services), as well as
              lawyers, bankers, auditors, and insurers.
              <br />
              <br />
              <strong>Other.</strong> You may permit us to share your personal
              information with other companies or entities of your choosing.
              Those uses will be subject to the privacy policies of the
              recipient entity or entities.
              <br />
              <br />- We may also share aggregated and/or anonymized data with
              others for their own uses.
            </p>
            <h1 className="embed-privacy-inner-titles">
              INTERNATIONAL TRANSFER
            </h1>
            <p>
              The Company has offices outside of the EU and has affiliates and
              service providers in the United States and in other countries.
              Your personal information may be transferred to or from the United
              States or other locations outside of your state, province, country
              or other governmental jurisdiction where privacy laws may not be
              as protective as those in your jurisdiction. <br />
              <br />
              EU users should read the important information provided below
              about transfer of personal information outside of the European
              Economic Area (EEA).
            </p>
            <h1 className="embed-privacy-inner-titles">
              HOW INFORMATION IS SECURED
            </h1>
            <p>
              We retain information we collect as long as it is necessary and
              relevant to fulfill the purposes outlined in this privacy policy.
              In addition, we retain personal information to comply with
              applicable law where required, prevent fraud, resolve disputes,
              troubleshoot problems, assist with any investigation, enforce our
              Terms of Use, and other actions permitted by law. To determine the
              appropriate retention period for personal information, we consider
              the amount, nature, and sensitivity of the personal information,
              the potential risk of harm from unauthorized use or disclosure of
              your personal information, the purposes for which we process your
              personal information and whether we can achieve those purposes
              through other means, and the applicable legal requirements. <br />
              <br />
              In some circumstances we may anonymize your personal information
              (so that it can no longer be associated with you) in which case we
              may use this information indefinitely without further notice to
              you.
              <br />
              <br /> We employ industry standard security measures designed to
              protect the security of all information submitted through the
              Services. However, the security of information transmitted through
              the internet can never be guaranteed. We are not responsible for
              any interception or interruption of any communications through the
              internet or for changes to or losses of data. Users of the
              Services are responsible for maintaining the security of any
              password, biometrics, user ID or other form of authentication
              involved in obtaining access to password protected or secure areas
              of any of our digital services. In order to protect you and your
              data, we may suspend your use of any of the Services, without
              notice, pending an investigation, if any breach of security is
              suspected.
            </p>
            <h1 className="embed-privacy-inner-titles">
              INFORMATION CHOICES AND CHANGES
            </h1>
            <p>
              <strong>
                Accessing, Updating, Correcting, and Deleting your Information
              </strong>
              <br />
              <br /> You may access information that you have voluntarily
              provided through your account on the Services, and to review,
              correct, or delete it by sending a request to info@3vo.me. You can
              request to change contact choices, opt-out of our sharing with
              others, and update your personal information and preferences.
            </p>
            <h1 className="embed-privacy-inner-titles">ELIGIBILITY</h1>
            <p>
              If you are under the age of majority in your jurisdiction of
              residence, you may use the Services only with the consent of or
              under the supervision of your parent or legal guardian. Consistent
              with the requirements of the Children’s Online Privacy Protection
              Act (COPPA), if we learn that we have received any information
              directly from a child under age 13 without first receiving his or
              her parent’s verified consent, we will use that information only
              to respond directly to that child (or his or her parent or legal
              guardian) to inform the child that he or she cannot use the Sites
              and subsequently we will delete that information.
            </p>
            <h1 className="embed-privacy-inner-titles">
              MARKETING COMMUNICATIONS AND SHARING
            </h1>
            <p>
              You may instruct us not to use your contact information to contact
              you regarding services, promotions, surveys and special events
              that might appeal to your interests by contacting us using the
              information below. You can also opt out by following the
              instructions located at the bottom of any commercial emails
              messages you may receive. <br /> <br /> Please note that,
              regardless of your request, we may still use and share certain
              information as permitted by applicable law. For example, you may
              not opt out of certain operational emails, such as those
              reflecting our relationship or transactions with you, or important
              notifications regarding the Services we are providing to you, such
              as service-related announcements (e.g., if our Services are
              temporarily suspended for maintenance). <br />
              <br />
              Or, if you have downloaded our mobile application and enabled push
              notifications on your mobile device, we may send you alerts and
              notifications through push notifications, for example, to
              communicate status updates on our Services. However, you may
              choose to disable these notifications (except for the initial
              notification intended to verify your identity).
            </p>
            <h1 className="embed-privacy-inner-titles">
              THIRD PARTY LINKS AND WEBSITES
            </h1>
            <p>
              This Privacy Notice does not address, and we are not responsible
              for, the privacy practices of any third parties, including those
              that operate websites to which our Sites link. The inclusion of a
              link on our Sites does not imply that we or our affiliates endorse
              the practices of the linked website.
            </p>
            <h1 className="embed-privacy-inner-titles">CO-BRANDED WEBSITES</h1>
            <p>
              In the event that our Sites link to other websites that include
              our branding, this Privacy Notice does not apply to those other
              websites. Visitors to those websites are advised to carefully read
              the notices on those individual websites.
            </p>
            <h1 className="embed-privacy-inner-titles">
              CHANGES TO THIS PRIVACY POLICY
            </h1>
            <p>
              We may change this privacy policy at any time. We encourage you to
              periodically review this page for the latest information on our
              privacy practices. If we make any changes, we will change the Last
              Updated date above.
              <br />
              <br /> Any modifications to this Privacy Policy will be effective
              upon our posting of the new terms and/or upon implementation of
              the changes to the Sites (or as otherwise indicated at the time of
              posting). In all cases, your continued use of the Sites or
              Services after the posting of any modified Privacy Policy
              indicates your acceptance of the terms of the modified Privacy
              Policy.
            </p>
            <h1 className="embed-privacy-inner-titles">CONTACT US</h1>
            <p>
              We welcome your comments or questions about this Policy, and you
              may contact us at: <br />
              <br />
              <strong>Email: info@3vo.me</strong>
            </p>
          </div>
          <div className="embed-background">
            <img src={logo} alt="3vo-superapp"></img>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
