import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./socialDescription.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function SocialDescription() {
  const { theme } = useTheme();

  return (
    <div
      className={`socialDescription ${theme === "light" ? "" : "dark-theme"}`}
    >
      <div className="socialDescription-container">
        <div className="socialDescription-title">Web3 SuperApp</div>
        <div className="socialDescription-para">
          The Home of the 3VO Ecosystem is 3vo App. We envision this wallet as a
          comprehensive solution for Creating, Delivering and Transacting all
          your digital value.
          <br />
          <br />
          By seamlessly integrating Social Media and Digital Assets, 3vo
          provides a simple, unified solution for your online presence. enabling
          user to perform value-based interactions.
          <br />
          <br />
          By leveraging on-ramp and off-ramp functionalities, users can easily
          engage in banking activities directly from the wallet. Think of the
          wallet as not only a secure storage space but also as a user's social
          media profile and decentralized application (DApp). It serves as the
          gateway to the 3vo ecosystem, enabling users to access and transact
          with their digital value effortlessly.
        </div>
        <div className="socialDescription-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
    </div>
  );
}

export default SocialDescription;
