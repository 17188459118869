import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./usecasesHero.css";

function UsecasesHero() {
  const { theme } = useTheme();

  return (
    <div className={`usecasesHero ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="usecasesHero-container">
        <div className="usecasesHero-title">
          <div>
            <div className="usecasesHero-title-title">
              <span className="specific-text">3VO</span> Usecases
            </div>
          </div>
          <div className="usecasesHero-title-subtitle">
            Your go-to platform for success in the Web3 era
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsecasesHero;
