import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./usecasesCall.css";

import logo from "../../assets/svg/logo-inner.svg";

function UsecasesCall() {
  const { theme } = useTheme();

  return (
    <div className={`usecasesCall ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="usecasesCall-container">
        <div className="usecasesCall-title">Reserve Your Spot</div>
        <div className="usecasesCall-action">
          <a href="/username" target="" rel="noreferrer">
            <div className="usecasesCall-action-button">
              Request Verification
            </div>
          </a>
        </div>
      </div>
      <div className="usecasesCall-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default UsecasesCall;
