import React, { useState, useEffect } from "react";
import { useTheme } from "../../ThemeProvider";
import "./defiFeatures.css";

import lock from "../../assets/svg/lock.svg";
import speed from "../../assets/svg/speed.svg";
import send from "../../assets/svg/send.svg";
import usdt from "../../assets/svg/usdt.svg";
import logo from "../../assets/svg/logo-circle.svg";
import flip from "../../assets/svg/flip.svg";
import wallet from "../../assets/svg/wallet.svg";
import wallet2 from "../../assets/svg/wallet2.svg";
import arrows from "../../assets/svg/arrows.svg";
import tokens from "../../assets/images/tokens.webp";

function DefiFeatures() {
  const { theme } = useTheme();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 550) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`defiFeatures ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="defiFeatures-container">
        {!isMobile ? (
          <>
            <div className="defiFeatures-feature">
              <div className="defiFeatures-text">
                <div className="defiFeatures-text-title">
                  Send and Receive Crypto
                </div>
                <div className="defiFeatures-text-description">
                  Use the best social media functions you love. Enjoy content
                  from people and brands that matter most to you, in one
                  customizable feed.
                </div>
              </div>
              <div className="defiFeatures-image-container-first">
                <div className="defiFeatures-image-first">
                  <img src={wallet} alt="3VO secure" />
                  <div className="defiFeatures-image-special">
                    <img src={arrows} alt="3VO fast" />
                  </div>
                  <img src={wallet2} alt="3VO easy" />
                </div>
              </div>
            </div>
            <div className="defiFeatures-feature">
              <div className="defiFeatures-image-container-second">
                <div className="defiFeatures-image-second">
                  <div className="defiFeatures-image-second-inner">
                    <div className="defiFeatures-image-second-inner-text">
                      <div className="defiFeatures-image-second-inner-text-token">
                        <img
                          className="defiFeatures-image-second-inner-text-token-image"
                          src={usdt}
                          alt="USDT Logo"
                        />
                        USDT
                      </div>
                      <div className="defiFeatures-image-second-inner-text-text">
                        100
                      </div>
                      <div className="defiFeatures-image-second-inner-text-balance">
                        Balance : 10,000
                      </div>
                    </div>
                  </div>
                  <div className="defiFeatures-image-second-inner">
                    <div className="defiFeatures-image-second-inner-text">
                      <div className="defiFeatures-image-second-inner-text-token">
                        <img
                          className="defiFeatures-image-second-inner-text-token-image"
                          src={logo}
                          alt="3VO Logo"
                        />
                        3VO
                      </div>
                      <div className="defiFeatures-image-second-inner-text-text">
                        2000
                      </div>
                      <div className="defiFeatures-image-second-inner-text-balance">
                        Balance : 0
                      </div>
                    </div>
                  </div>
                  <div className="defiFeatures-image-flip">
                    <img src={flip} alt="3VO Flip" />
                  </div>
                </div>
              </div>
              <div className="defiFeatures-text-r">
                <div className="defiFeatures-text-title">
                  Fast Token Swapping
                </div>
                <div className="defiFeatures-text-description">
                  Use the best social media functions you love. Enjoy content
                  from people and brands that matter most to you, in one
                  customizable feed.
                </div>
              </div>
            </div>
            <div className="defiFeatures-feature">
              <div className="defiFeatures-text">
                <div className="defiFeatures-text-title">
                  Simple, Fast and Secure
                </div>
                <div className="defiFeatures-text-description">
                  Use the best social media functions you love. Enjoy content
                  from people and brands that matter most to you, in one
                  customizable feed.
                </div>
              </div>
              <div className="defiFeatures-image-container-third">
                <div className="defiFeatures-image-third">
                  <img src={lock} alt="3VO secure" />
                  <img src={speed} alt="3VO fast" />
                  <img src={send} alt="3VO easy" />
                </div>
              </div>
            </div>
            <div className="defiFeatures-feature">
              <div className="defiFeatures-image-container-fourth-border">
                <div className="defiFeatures-image-container-fourth">
                  <img src={tokens} alt="3VO Tokens" />
                </div>
              </div>
              <div className="defiFeatures-text-r">
                <div className="defiFeatures-text-title">
                  Connect To The World of DeFi
                </div>
                <div className="defiFeatures-text-description">
                  Use the best social media functions you love. Enjoy content
                  from people and brands that matter most to you, in one
                  customizable feed.
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="defiFeatures-feature">
              <div className="defiFeatures-image-container-first">
                <div className="defiFeatures-image-first">
                  <img src={wallet} alt="3VO secure" />
                  <div className="defiFeatures-image-special">
                    <img src={arrows} alt="3VO fast" />
                  </div>
                  <img src={wallet2} alt="3VO easy" />
                </div>
              </div>
              <div className="defiFeatures-text">
                <div className="defiFeatures-text-title">
                  Send and Receive Crypto
                </div>
                <div className="defiFeatures-text-description">
                  Use the best social media functions you love. Enjoy content
                  from people and brands that matter most to you, in one
                  customizable feed.
                </div>
              </div>
            </div>
            <div className="defiFeatures-feature">
              <div className="defiFeatures-image-container-second">
                <div className="defiFeatures-image-second">
                  <div className="defiFeatures-image-second-inner">
                    <div className="defiFeatures-image-second-inner-text">
                      <div className="defiFeatures-image-second-inner-text-token">
                        <img
                          className="defiFeatures-image-second-inner-text-token-image"
                          src={usdt}
                          alt="USDT Logo"
                        />
                        USDT
                      </div>
                      <div className="defiFeatures-image-second-inner-text-text">
                        100
                      </div>
                      <div className="defiFeatures-image-second-inner-text-balance">
                        Balance : 10,000
                      </div>
                    </div>
                  </div>
                  <div className="defiFeatures-image-second-inner">
                    <div className="defiFeatures-image-second-inner-text">
                      <div className="defiFeatures-image-second-inner-text-token">
                        <img
                          className="defiFeatures-image-second-inner-text-token-image"
                          src={logo}
                          alt="3VO Logo"
                        />
                        3VO
                      </div>
                      <div className="defiFeatures-image-second-inner-text-text">
                        2000
                      </div>
                      <div className="defiFeatures-image-second-inner-text-balance">
                        Balance : 0
                      </div>
                    </div>
                  </div>
                  <div className="defiFeatures-image-flip">
                    <img src={flip} alt="3VO Flip" />
                  </div>
                </div>
              </div>
              <div className="defiFeatures-text-r">
                <div className="defiFeatures-text-title">
                  Fast Token Swapping
                </div>
                <div className="defiFeatures-text-description">
                  Use the best social media functions you love. Enjoy content
                  from people and brands that matter most to you, in one
                  customizable feed.
                </div>
              </div>
            </div>
            <div className="defiFeatures-feature">
              <div className="defiFeatures-image-container-third">
                <div className="defiFeatures-image-third">
                  <img src={lock} alt="3VO secure" />
                  <img src={speed} alt="3VO fast" />
                  <img src={send} alt="3VO easy" />
                </div>
              </div>
              <div className="defiFeatures-text">
                <div className="defiFeatures-text-title">
                  Simple, Fast and Secure
                </div>
                <div className="defiFeatures-text-description">
                  Use the best social media functions you love. Enjoy content
                  from people and brands that matter most to you, in one
                  customizable feed.
                </div>
              </div>
            </div>
            <div className="defiFeatures-feature">
              <div className="defiFeatures-image-container-fourth-border">
                <div className="defiFeatures-image-container-fourth">
                  <img src={tokens} alt="3VO Tokens" />
                </div>
              </div>
              <div className="defiFeatures-text-r">
                <div className="defiFeatures-text-title">
                  Connect To The World of DeFi
                </div>
                <div className="defiFeatures-text-description">
                  Use the best social media functions you love. Enjoy content
                  from people and brands that matter most to you, in one
                  customizable feed.
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DefiFeatures;
