import React, { useEffect } from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import ProjectHero from "../project/projectHero/projectHero.js";
import ProjectTeam from "../project/projectTeam/projectTeam.js";
import ProjectAdvisors from "../project/projectAdvisors/projectAdvisors.js";
import ProjectRoadmap from "../project/projectRoadmap/projectRoadmap.js";

function Project() {
  const { theme } = useTheme();

  document.title = "3VO | Project";

  useEffect(() => {
    // Function to handle auto-scrolling
    const handleAutoScroll = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          let offset = element.getBoundingClientRect().top;
          if (hash === "#advisors") {
            offset += 200; // Add additional offset for #advisors
          }
          window.scrollTo({
            top: window.pageYOffset + offset - 70, // Adjusted for header
            behavior: "smooth",
          });
        }
      }
    };

    handleAutoScroll();
    window.addEventListener("hashchange", handleAutoScroll);

    return () => {
      window.removeEventListener("hashchange", handleAutoScroll);
    };
  }, []);

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <ProjectHero />
      <ProjectTeam />
      <ProjectAdvisors />
      <ProjectRoadmap />
      <Footer />
    </div>
  );
}

export default Project;
