import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./freedomHero.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function FreedomHero() {
  const { theme } = useTheme();

  return (
    <div className={`freedomHero ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="freedomHero-container">
        <div className="freedomHero-title">
          <div>
            <div className="freedomHero-title-title">
              <span className="specific-text">3VO</span> Hub
            </div>
          </div>
          {/* <div className="freedomHero-title-subtitle">
            Home of the 3VO Ecosystem
          </div> */}
        </div>
        <div className="freedomHero-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
    </div>
  );
}

export default FreedomHero;
