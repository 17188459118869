import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./dappsCards.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

import dappBuilder from "../../assets/svg/dappBuilder.svg";
import library from "../../assets/svg/library.svg";
import analytics from "../../assets/svg/analytics.svg";
import assistant from "../../assets/svg/assistant.svg";
import token from "../../assets/svg/tokens.svg";
import transaction from "../../assets/svg/transaction.svg";
import issues from "../../assets/svg/issue.svg";
import proposals from "../../assets/svg/proposal.svg";
import history from "../../assets/svg/history.svg";
import message from "../../assets/svg/messages.svg";
import podcast from "../../assets/svg/podcast.svg";
import stream from "../../assets/svg/stream.svg";
import studio from "../../assets/svg/studio.svg";
import livetip from "../../assets/svg/livetip.svg";
import music from "../../assets/svg/music.svg";
import education from "../../assets/svg/education.svg";
import news from "../../assets/svg/news.svg";
import commerce from "../../assets/svg/commerce.svg";
import auction from "../../assets/svg/auction.svg";

function DappsCards() {
  const { theme } = useTheme();

  const creatorIcons = [
    {
      icon: dappBuilder,
      title: "Dapp Builder",
    },
    {
      icon: library,
      title: "Template Library",
    },
    {
      icon: analytics,
      title: "Analytics",
    },
    {
      icon: assistant,
      title: "AI Assistant",
    },
  ];

  const streamIcons = [
    {
      icon: podcast,
      title: "Podcasts",
    },
    {
      icon: stream,
      title: "Live Streams",
    },
    {
      icon: studio,
      title: "Control Studio",
    },
    {
      icon: livetip,
      title: "Live Tipping",
    },
    {
      icon: analytics,
      title: "Analytics",
    },
  ];

  const tokenSmithIcons = [
    {
      icon: token,
      title: "Mint Tokens",
    },
    {
      icon: library,
      title: "Template Library",
    },
    {
      icon: transaction,
      title: "Transactions",
    },
    {
      icon: analytics,
      title: "Analytics",
    },
    {
      icon: assistant,
      title: "AI Assistant",
    },
  ];

  const daoIcons = [
    {
      icon: issues,
      title: "Issues",
    },
    {
      icon: proposals,
      title: "Proposals",
    },
    {
      icon: history,
      title: "History",
    },
    {
      icon: message,
      title: "Messages",
    },
    {
      icon: assistant,
      title: "AI Assistant",
    },
  ];

  const otherIcons = [
    {
      icon: music,
      title: "Music",
    },
    {
      icon: education,
      title: "Education",
    },
    {
      icon: news,
      title: "News",
    },
    {
      icon: commerce,
      title: "DeCommerce",
    },
    {
      icon: auction,
      title: "Auctions",
    },
  ];

  return (
    <div className={`dappsCards ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="dappsCards-container">
        <div className="dappsCards-row">
          <div className="dappsCards-dapp">
            <div className="dappsCards-dapp-text">
              <div className="dappsCards-dapp-text-title">3EATOR</div>
              <div className="dappsCards-dapp-text-description">
                Create content on your customizable Dapps. A no-code tool to
                build social web3 applications for the masses
              </div>
            </div>
            <div className="dappsCards-dapp-icons">
              {creatorIcons.map((icon, index) => (
                <div key={index} className="dappsCards-dapp-icons-icon">
                  <img src={icon.icon} alt="Feature Icon" />
                  <div className="dappsCards-dapp-icons-title">
                    {icon.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="dappsCards-dapp">
            <div className="dappsCards-dapp-text">
              <div className="dappsCards-dapp-text-title">3REAM</div>
              <div className="dappsCards-dapp-text-description">
                Talk with friends via text, voice, or video. Send / receive
                money or share and invite to activities directly from the chat.
              </div>
            </div>
            <div className="dappsCards-dapp-icons">
              {streamIcons.map((icon, index) => (
                <div key={index} className="dappsCards-dapp-icons-icon">
                  <img src={icon.icon} alt="Feature Icon" />
                  <div className="dappsCards-dapp-icons-title">
                    {icon.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="dappsCards-row">
          <div className="dappsCards-dapp">
            <div className="dappsCards-dapp-text">
              <div className="dappsCards-dapp-text-title">TokenSmith</div>
              <div className="dappsCards-dapp-text-description">
                Transform your value into a token. User-friendly tool to create
                any token with no coding required, powered by a library of
                audited smart contracts.
              </div>
            </div>
            <div className="dappsCards-dapp-icons">
              {tokenSmithIcons.map((icon, index) => (
                <div key={index} className="dappsCards-dapp-icons-icon">
                  <img src={icon.icon} alt="Feature Icon" />
                  <div className="dappsCards-dapp-icons-title">
                    {icon.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="dappsCards-dapp">
            <div className="dappsCards-dapp-text">
              <div className="dappsCards-dapp-text-title">DAOmocracy</div>
              <div className="dappsCards-dapp-text-description">
                Turn any organization into a DAO. Ready to deploy DAO to enable
                democratic, inclusive, and decentralized organizations.
              </div>
            </div>
            <div className="dappsCards-dapp-icons">
              {daoIcons.map((icon, index) => (
                <div key={index} className="dappsCards-dapp-icons-icon">
                  <img src={icon.icon} alt="Feature Icon" />
                  <div className="dappsCards-dapp-icons-title">
                    {icon.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="dappsCards-row">
          <div className="dappsCards-dapp-special">
            <div className="dappsCards-dapp-text">
              <div className="dappsCards-dapp-text-title">And Many More ..</div>
              <div className="dappsCards-dapp-text-description">
                Dive into a realm of endless possibilities with the 3vo app! Not
                only will you find a myriad of innovative Dapps like social
                networking tools and financial services, but you'll also unlock
                the gateway to the future of decentralized technology.
              </div>
            </div>
            <div className="dappsCards-dapp-icons">
              {otherIcons.map((icon, index) => (
                <div key={index} className="dappsCards-dapp-icons-icon">
                  <img src={icon.icon} alt="Feature Icon" />
                  <div className="dappsCards-dapp-icons-title">
                    {icon.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="dappsCards-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
    </div>
  );
}

export default DappsCards;
