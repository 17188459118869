import React from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import UsecasesHero from "../usecases/usecasesHero/usecasesHero.js";
import UsecasesStatement from "../usecases/usecasesStatement/usecasesStatement.js";
import UsecasesCards from "../usecases/usecasesCards/usecasesCards.js";
import UsecasesMore from "../usecases/usecasesMore/usecasesMore.js";
import UsecasesCall from "../usecases/usecasesCall/usecasesCall.js";

function Usecases() {
  const { theme } = useTheme();

  document.title = "3VO | Usecases";

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <UsecasesHero />
      <UsecasesStatement />
      <UsecasesCards />
      <UsecasesMore />
      <UsecasesCall />
      <Footer />
    </div>
  );
}

export default Usecases;
