import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./projectTeam.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import ayman from "../../assets/team/AymanB.webp";
import wiktor from "../../assets/team/victor.webp";
import mo from "../../assets/team/mo.webp";
import mikhail from "../../assets/team/Mikhail.webp";
import alex from "../../assets/team/alex.webp";
import garima from "../../assets/team/Garmiax.webp";
import dimitry from "../../assets/team/dmi.webp";
import saif from "../../assets/team/saif.webp";
import jp from "../../assets/team/jes.webp";
import parker from "../../assets/team/parker.webp";
import dania from "../../assets/team/Dania.webp";
import raluca from "../../assets/team/Raluca.webp";
import chris from "../../assets/team/chris.webp";
import seven from "../../assets/team/7.webp";

function ProjectTeam() {
  const { theme } = useTheme();

  const team = [
    {
      name: "Ayman Sayed",
      title: "CEO",
      picture: ayman,
      twitter: "https://x.com/aymanonline",
      linkedin: "https://www.linkedin.com/in/aymanonline/",
    },
    {
      name: "Wiktor Gryzb",
      title: "Operations",
      picture: wiktor,
      twitter: "https://x.com/Wikist_",
      linkedin: "https://www.linkedin.com/in/wiktorgrzyb/",
    },
    {
      name: "Mohammad Saad",
      title: "Product & Design",
      picture: mo,
      twitter: "https://twitter.com/OG_Mo_7",
      linkedin: "https://www.linkedin.com/in/og-mohd/",
    },
    {
      name: "Mikhail Zhbankov",
      title: "Technology",
      picture: mikhail,
      twitter: "",
      linkedin: "https://www.linkedin.com/in/mikhail-zhbankov-11515a195/",
    },
    {
      name: "Alex Belov",
      title: "Information & Security",
      picture: alex,
      twitter: "",
      linkedin: "https://www.linkedin.com/in/roosso/",
    },
    {
      name: "Garima Gupta",
      title: "Finance",
      picture: garima,
      twitter: "",
      linkedin: "https://www.linkedin.com/in/garima-gupta-25207510",
    },
    {
      name: "Dmitry Dyachenko",
      title: "Mobile Development",
      picture: dimitry,
      twitter: "",
      linkedin: "https://www.linkedin.com/in/ddnproger/",
    },
    {
      name: "Saif Amer",
      title: "CBDO",
      picture: saif,
      twitter: "https://x.com/saifamerr",
      linkedin: "https://www.linkedin.com/in/saifamer/",
    },
    {
      name: "Jasenko Purkovic",
      title: "Business",
      picture: jp,
      twitter: "",
      linkedin: "",
    },
    {
      name: "Parker Tag",
      title: "Communications",
      picture: parker,
      twitter: "https://x.com/ParkerDefi",
      linkedin: "https://www.linkedin.com/in/parkertag/",
    },
    {
      name: "Dania Bizri",
      title: "Public Relations",
      picture: dania,
      twitter: "",
      linkedin: "",
    },
    {
      name: "Raluca Gabriela",
      title: "Community",
      picture: raluca,
      twitter: "https://x.com/Ha_Raluca",
      linkedin: "",
    },
    {
      name: "Chris Orzo",
      title: "Strategy / Fundraising",
      picture: chris,
      twitter: "",
      linkedin: "",
    },
    {
      name: "Development Team",
      title: "",
      picture: seven,
      twitter: "",
      linkedin: "https://www.linkedin.com/in/thechrisorza/",
    },
  ];

  return (
    <div
      id="team"
      className={`projectTeam ${theme === "light" ? "" : "dark-theme"}`}
    >
      <div className="projectTeam-container">
        <div className="projectTeam-title">
          <div>
            <div className="projectTeam-title-title">Team</div>
          </div>
        </div>
        <div className="projectTeam-team">
          {team.map((member, index) => (
            <div key={index} className="projectTeam-member">
              <div className="projectTeam-member-picture">
                <img src={member.picture} alt={member.name} />
                <div className="projectTeam-member-links">
                  {member.twitter ? (
                    <a
                      href={member.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="projectTeam-member-links-twitter">
                        <FontAwesomeIcon icon={faXTwitter} />
                      </div>
                    </a>
                  ) : (
                    <></>
                  )}
                  {member.linkedin && member.twitter && (
                    <div className="projectTeam-member-links-design"></div>
                  )}
                  {member.linkedin ? (
                    <a
                      href={member.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="projectTeam-member-links-linkedin">
                        <FontAwesomeIcon icon={faLinkedin} />
                      </div>
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="projectTeam-member-text">
                <div className="projectTeam-member-text-name">
                  {member.name}
                </div>
                <div className="projectTeam-member-text-title">
                  {member.title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="projectTeam-background">
        <img src={logo} alt="3vo-superapp" />
      </div>
    </div>
  );
}

export default ProjectTeam;
