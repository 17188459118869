import React from "react";
import { useTheme } from "../ThemeProvider";
import "./embed.css";
import Header from "../components/header/mainHeader.js";
import Footer from "../components/footer/mainFooter.js";
import SocialHero from "../social/socialHero/socialHero.js";
import SocialDescription from "../social/socialDescription/socialDescription.js";
import SocialCards from "../social/socialCards/socialCards.js";
import SocialFeatures from "../social/socialFeatures/socialFeatures.js";
import SocialCall from "../social/socialCall/socialCall.js";

function Social() {
  const { theme } = useTheme();

  document.title = "3VO | App";

  return (
    <div className={`subpage ${theme === "light" ? "" : "dark-theme"}`}>
      <Header />
      <SocialHero />
      <SocialDescription />
      <SocialCards />
      <SocialFeatures />
      <SocialCall />
      <Footer />
    </div>
  );
}

export default Social;
