import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./socialCall.css";

import playStore from "../../assets/images/googlePlay.png";
import appStore from "../../assets/images/appstore.svg";

function SocialCall() {
  const { theme } = useTheme();

  return (
    <div className={`socialCall ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="socialCall-container">
        <div className="socialCall-title">Get 3VO App V0.2 Now!</div>
        <div className="socialCall-action">
          <div
            className="socialCall-action-soon"
            href=""
            target=""
            rel="noreferrer"
          >
            <img src={appStore} />
          </div>
          <a
            className="socialCall-action-live"
            href="https://play.google.com/store/apps/details?id=me.threevo.client"
            target="_blank"
            rel="noreferrer"
          >
            <img src={playStore} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default SocialCall;
