import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./usecasesStatement.css";

function UsecasesStatement() {
  const { theme } = useTheme(); 

  return (
    <div
      className={`usecasesStatement ${theme === "light" ? "" : "dark-theme"}`}
    >
      <div className="usecasesStatement-container">
        <div className="usecasesStatement-title">
          Tailored solution just for you
        </div>
      </div>
    </div>
  );
}

export default UsecasesStatement;
