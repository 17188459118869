import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./defiDownload.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

import soonApple from "../../assets/images/soon-apple.webp";
import soonAndroid from "../../assets/images/soon-android.webp";

import app from "../../assets/files/3VO-DeFi-0.1b2.apk";

function DefiDownload() {
  const { theme } = useTheme();

  return (
    <div className={`defiDownload ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="defiDownload-container">
        <div className="defiDownload-title">
          <div className="defiDownload-title-version">( Public Alpha )</div>
          <div className="defiDownload-title-text">
            Get Early Access to 3VO DeFi Wallet
          </div>
        </div>
        <div className="defiDownload-soon">
          <img src={soonApple} alt="3VO Coming soon to Apple Store" />
          <img src={soonAndroid} alt="3VO Coming soon to Play Store" />
        </div>
        <div className="defiDownload-disclaimer">
          <div className="defiDownload-disclaimer-title">Disclaimer</div>
          <div className="defiDownload-disclaimer-text">
            This APK file is an ALPHA build for 3VO DeFi wallet, The intention
            of distributing the App is to test it publicly and get feedback from
            the community.
            <br />
            This is not a ready product to be used as a financial tool, use at
            your own caution, 3VO is not responsible for any damages from using
            this APK.
            <br />
            By downloading from the link above, you accept the risks mentioned
            in the disclaimer above.
          </div>
        </div>
        <div className="defiDownload-buttons">
          <div className="defiDownload-buttons-action">
            <a
              // href={app}
              // target="_blank"
              // rel="noreferrer"
              className="defiDownload-buttons-action-button"
            >
              <div className="defiDownload-buttons-action-button-title">
                Under Maintenance
              </div>
              <div className="defiDownload-buttons-action-button-subtitle">
                3VO DeFi V0.1b2 APK
              </div>
            </a>
          </div>
          <div className="defiDownload-buttons-links">
            <div className="defiDownload-buttons-links-design"></div>
            <a
              href="https://www.youtube.com/watch?v=N0M4XGkpCn4"
              target="_blank"
              rel="noreferrer"
            >
              <div className="defiDownload-buttons-links-link">
                How to install
              </div>
            </a>
            <div className="defiDownload-buttons-links-design"></div>
            <a href="/contact" target="_blank" rel="noreferrer">
              <div className="defiDownload-buttons-links-link">Report Bug</div>
            </a>
            <div className="defiDownload-buttons-links-design"></div>
          </div>
        </div>
      </div>
      <div className="defiDownload-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default DefiDownload;
