import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./defiDescription.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function DefiDescription() {
  const { theme } = useTheme();

  return (
    <div className={`defiDescription ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="defiDescription-container">
        <div className="defiDescription-title">DeFi Wallet For Everyone</div>
        <div className="defiDescription-para">
          Multi-chain hybrid CeFi-DeFi Wallet to store all digital value. Users
          can create centralized wallets, or create / add their DeFi Wallets.
          <br />
          <br />
          With our wallet, users can effortlessly create centralized wallets,
          offering the convenience and security of traditional financial
          institutions, or seamlessly integrate their existing DeFi wallets,
          tapping into the vast array of decentralized protocols and
          opportunities available in the ever-evolving DeFi ecosystem.
        </div>
        <div className="defiDescription-background">
          <img src={logo} alt="3vo-superapp"></img>
        </div>
      </div>
    </div>
  );
}

export default DefiDescription;
